import React,{ createContext, useEffect, useContext, useReducer } from 'react';
import SocketIOClient from 'socket.io-client';
import { SOCKET_URL } from '../utils/constants';
const SocketContext = createContext();
const userData =
localStorage.getItem("user") !== undefined &&
  localStorage.getItem("user") !== null
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const socketInitState = {
  socket: null,
};
const socketReducer = (state, action) => {
  switch (action.type) {
    case 'SOCKET_CONNECTED':
      if (state.socket && state.isConnected) {
        return state; // socket already connected, no need to create a new connection
      }
      return {
        socket: SocketIOClient(SOCKET_URL,{

          query: {
            "persistentId": userData?.username,
          },
        }),
        isConnected: true,
      };
    case 'SOCKET_DISCONNECT':
      if (!state.socket || !state.isConnected) {
        return state; // socket not connected, nothing to disconnect
      }
      state.socket.disconnect();
      return {
        socket: null,
        isConnected: false,
      };
    default:
      return state;
  }
};
const SocketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(socketReducer, socketInitState);

  const connectSocket = () => {
    if (!state.socket || !state.isConnected) {
      dispatch({ type: 'SOCKET_CONNECTED'});
    }
  };

  const disconnectSocket = () => {
    if (state.socket) {
      state.socket.disconnect();
    }
    dispatch({ type: 'SOCKET_DISCONNECT' });
  };

  useEffect(() => {
    if (state.socket) {
      state.socket.on('connect', () => {
        // console.log('connected to the server');
      });
    }
  }, [state.socket]);

  return (
    <SocketContext.Provider
      value={{ socket: state.socket, connectSocket, disconnectSocket }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
export default SocketProvider;
import React, { useState, useEffect, Fragment } from "react"
import { useLocation } from "react-router-dom";
import AccountDetails from "../componenets/account/AccountDetails";
import CasinoHistory from "../componenets/account/CasinoHistory";
import ChangePassword from "../componenets/account/ChangePassword";
import Transfer from "../componenets/account/Transfer";
import MobileAuthSidebar from "../componenets/shared/MobileAuthSidebar";
import MobileSidebar from "../componenets/shared/MobileSidebar";
import NavBar from "../componenets/shared/NavBar";

const Account = () => {
    const [isAuthMobileSideBar, setisAuthMobileSideBar] = useState(false)
    const [isSidebarOpened, setisSidebarOpened] = useState(false)

    const handleOpenAuthSidebar = () => {
      setisSidebarOpened(false);
      setisAuthMobileSideBar(true);
      const htmlElement = document.querySelector("html");
      const classNames = [
        "mm-opened",
        "mm-blocking",
        "mm-background",
        "mm-right",
        "mm-next",
        "mm-effect-listitems-slide",
        "mm-shadow-page",
        "mm-opening"
      ];
      classNames.forEach(className => {
        htmlElement.classList.add(className);
      });
    };

    const handleCloseAuthSidebar = () => {
      setisAuthMobileSideBar(false);
      const htmlElement = document.querySelector("html");
      const classNames = [
        "mm-opened",
        "mm-blocking",
        "mm-background",
        "mm-right",
        "mm-next",
        "mm-effect-listitems-slide",
        "mm-shadow-page",
        "mm-opening"
      ];
      classNames.forEach(className => {
        htmlElement.classList.remove(className);
      });
    };
    const handleOpenMobileSideBar = () => {
      // this function will change the style of html balise style and add like the current site we clone.
      setisAuthMobileSideBar(false);
      setisSidebarOpened(true);
      //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
      const htmlElement = document.querySelector("html");
      const classNames = [
        "mm-opened",
        "mm-blocking",
        "mm-background",
        "mm-next",
        "mm-effect-listitems-slide",
        "mm-shadow-page",
        "mm-opening"
      ];
      classNames.forEach(className => {
        htmlElement.classList.add(className);
      });
      // htmlElement.classList.remove('mm-right');
      // classNames.classList.toggle('mm-right',isSidebarOpened)
    };
    
    const handleCloseMobileSideBar = () => {
      // this function will change the style of html balise style and add like the current site we clone.
      setisSidebarOpened(false);
      //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
      const htmlElement = document.querySelector("html");
      const classNames = [
        "mm-opened",
        "mm-blocking",
        "mm-background",
        "mm-next",
        "mm-effect-listitems-slide",
        "mm-shadow-page",
        "mm-opening"
      ];
      classNames.forEach(className => {
        htmlElement.classList.remove(className);
      });
      // htmlElement.classList.remove('mm-right');
      // classNames.classList.toggle('mm-right',isSidebarOpened)
    };
    
    const [activeTab, setactiveTab] = useState('account')
    const location = useLocation();
    const tabFrom = location.state?.activeTab
    useEffect(() => {
      // setisAuthMobileSideBar(false)
      // setisSidebarOpened(false)
      if(tabFrom) {

      // handleClickMobileSideBar()
      // handleClickAuthMobileSidebar()
        setactiveTab(tabFrom)
        handleCloseAuthSidebar()
      }
    }, [tabFrom])

    const handleToggleSidebar = () => {
      if (isSidebarOpened) {
        handleCloseMobileSideBar();
      } else {
        handleOpenMobileSideBar();
      }
    }
    return (
    <Fragment>
    <MobileAuthSidebar
      isOther={isSidebarOpened}
      isAuthMobileSideBar={isAuthMobileSideBar}
      handleClose={handleCloseAuthSidebar}
    />
    <MobileSidebar 
      isOther={isAuthMobileSideBar}
      isOpen={isSidebarOpened}
      handleClose={handleCloseMobileSideBar}
    />
 
          <div id="root" className="mm-page mm-slideout" style={{ minHeight: isAuthMobileSideBar && '606px' }}>
          <NavBar 
            isAuthMobileSideBar={isAuthMobileSideBar}
            isSidebarOpened={isSidebarOpened}
            handleOpenAuth={handleOpenAuthSidebar}
            handleCloseAuth={handleCloseAuthSidebar}
            handleOpenSidebar={handleOpenMobileSideBar}
            handleCloseSidebar={handleCloseAuthSidebar}
            handleToggleSidebar={handleToggleSidebar}
          />
                <div className="container-tig">
                    <div className="row no-gutters">
                        <div className="col-xl-3 col-lg-3 col-12">
                            <div className="account-nav-wrap">
                                <div className="list-group nav-account">
                                    <span
                                        onClick={()=> {setactiveTab('account')}}
                                        className={`list-group-item list-group-item-action ${activeTab === 'account' && 'active'}`}
                                        title="My account"
                                    >
                                        My Account					
                                    </span>
                                    <span
                                        onClick={()=> {setactiveTab('change_password')}}
                                        className={`list-group-item list-group-item-action ${activeTab === 'change_password' && 'active'}`}
                                        title="Change Password">
                                        Change Password					
                                    </span>
                                    <span
                                        onClick={()=> {setactiveTab('transactions')}}
                                        className={`list-group-item list-group-item-action ${activeTab === 'transactions' && 'active'}`}
                                        title="Transactions">
                                        Transactions					
                                    </span>
                                    <span
                                        onClick={()=> {setactiveTab('casino_history')}}
                                        className={`list-group-item list-group-item-action ${activeTab === 'casino_history' && 'active'}`}
                                        title="Casino History"
                                    >
                                        Casino History					
                                    </span>
                                    <span 
                                        className="list-group-item list-group-item-action btn-logout" 
                                        title="Logout">
                                        Logout
                                    </span>
                                </div>
                            </div>
                        </div>
                            <AccountDetails activeTab={activeTab} />
                            <CasinoHistory activeTab={activeTab} />
                            <ChangePassword activeTab={activeTab} />
                            <Transfer activeTab={activeTab} />
                    </div>
                </div>
            </div>
        </Fragment>

    );
}
export default Account;

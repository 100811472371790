import React, { useState, useEffect, Fragment } from "react";
import GamesList from "../componenets/miniGames/GamesList";
import NavBar from "../componenets/shared/NavBar";
import MobileAuthSidebar from "../componenets/shared/MobileAuthSidebar";
import MobileSidebar from "../componenets/shared/MobileSidebar";
import LoginModal from "../componenets/loginModal/LoginModal";
import { useSocket } from "../context/SocketContext";
import { useLocation } from "react-router-dom";

const MiniGames = () => {
  const [isAuthMobileSideBar, setisAuthMobileSideBar] = useState(false);
  const [isSidebarOpened, setisSidebarOpened] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [miniGamesBlocked, setminiGamesBlocked] = useState(false);
  const { socket } = useSocket();
  const location = useLocation();

  const checkIfMiniGamesBlocked = (data) => {
    if (data.mini_games) {
      setminiGamesBlocked(true);
    }
  };
  useEffect(() => {
    handleCloseMobileSideBar(); // Close the sidebar when the location changes
  }, [location]);
  // check if casino maintenance.
  useEffect(() => {
    if (socket) {
      socket.on("status", checkIfMiniGamesBlocked);
    }
    return () => {
      if (socket) {
        socket.off("status", checkIfMiniGamesBlocked);
      }
    };
  }, [socket]);

  const handleOpenAuthSidebar = () => {
    setisSidebarOpened(false);
    setisAuthMobileSideBar(true);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.add(className);
    });
  };

  const handleCloseAuthSidebar = () => {
    setisAuthMobileSideBar(false);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.remove(className);
    });
  };
  const handleOpenMobileSideBar = () => {
    setisAuthMobileSideBar(false);
    // this function will change the style of html balise style and add like the current site we clone.
    setisSidebarOpened(true);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.add(className);
    });
    // htmlElement.classList.remove('mm-right');
    // classNames.classList.toggle('mm-right',isSidebarOpened)
  };

  const handleCloseMobileSideBar = () => {
    // this function will change the style of html balise style and add like the current site we clone.
    setisSidebarOpened(false);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.remove(className);
    });
  };

  const handleToggleSidebar = () => {
    if (isSidebarOpened) {
      handleCloseMobileSideBar();
    } else {
      handleOpenMobileSideBar();
    }
  }

  return (
    <Fragment>
      <LoginModal openLogin={openLogin} setOpenLogin={setOpenLogin} />
      <MobileAuthSidebar
        isOther={isSidebarOpened}
        isAuthMobileSideBar={isAuthMobileSideBar}
        handleClose={handleCloseAuthSidebar}
      />
      <MobileSidebar
        isOther={isAuthMobileSideBar}
        isOpen={isSidebarOpened}
        handleClose={handleCloseMobileSideBar}
      />

      <div id="root" className="mm-page mm-slideout">
        <NavBar
          isAuthMobileSideBar={isAuthMobileSideBar}
          isSidebarOpened={isSidebarOpened}
          handleOpenAuth={handleOpenAuthSidebar}
          handleCloseAuth={handleCloseAuthSidebar}
          handleOpenSidebar={handleOpenMobileSideBar}
          handleCloseSidebar={handleCloseAuthSidebar}
          handleToggleSidebar={handleToggleSidebar}
          activeNav={"mini-games"}
        />
        <div class="header-indecator"></div>
        {miniGamesBlocked ? (
          <h3 style={{ color: "white" }}>
            Mini Games is currently in maintenance mode.
          </h3>
        ) : (
          <GamesList openLogin={openLogin} setOpenLogin={setOpenLogin} />
        )}
      </div>
    </Fragment>
  );
};
export default MiniGames;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { authentification } from "../../redux/actions/auth";
import { RESET_AUTH_ERROR } from "../../redux/types";
import { FaUser, FaLock, FaEye, FaEyeSlash, FaUserAlt, FaSignInAlt, FaSignOutAlt, FaRegMoneyBillAlt, FaKey, FaListAlt, FaHistory, FaPowerOff } from "react-icons/fa"
import { BiRefresh } from "react-icons/bi"
import { useSocket } from '../../context/SocketContext';
import { Link } from "react-router-dom";
import { convertCashbackApi } from '../../redux/actions/user';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
// opened : mm-menu mm-effect-listitems-slide mm-shadow-page mm-offcanvas mm-right mm-next mm-hasnavbar-top-1 mm-current mm-opened
// not opened: mm-menu mm-effect-listitems-slide mm-shadow-page mm-offcanvas mm-right mm-next mm-hasnavbar-top-1
const MobileAuthNav = ({
  isAuthMobileSideBar,
  isOther,
  handleClose
}) => {
  const { socket } = useSocket()
  const userData = localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : null
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token") ?? null;

  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [passwordplain, setpasswordplain] = useState(false);
  const [balance, setbalance] = useState(null);
  const [cashback, setcashback] = useState(null);
  const [convertCashback, setConvertCashback] = useState(false);


  const handleUsername = (e) => {
    setusername(e.target.value);
    dispatch({
      type: RESET_AUTH_ERROR
    })
  }

  const togglePasswordText = () => {
    setpasswordplain(!passwordplain)
  }

  const handlePassword = (e) => {
    setpassword(e.target.value);
    dispatch({
      type: RESET_AUTH_ERROR
    })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    let body = {
      username: username,
      password: password
    }
    dispatch(authentification(body))
  }

  useEffect(() => {
    if (socket && userData !== null) {
      const fpPromise = FingerprintJS.load();
      fpPromise
        .then((fp) => fp.get())
        .then((result) => {
          socket.emit("Getbalance", {
            id: userData.id,
            username: userData.username,
            fingerprint: result.visitorId,
          });
        });
      // listning on balance event.
      socket.on('balance', (data) => {
        setbalance(data.balance);
        setcashback(data.cashback);

      });
    }
    return () => {
      if (socket) {
        socket.off("balance")
      }
    }
  }, [socket])

  useEffect(() => {
    if (cashback > 0) {
      dispatch(convertCashbackApi());
    }
    return () => {
    };
  }, [convertCashback]);

  return (
    <nav
      id="nav-mobile-user"
      className={`mm-menu mm-effect-listitems-slide mm-shadow-page mm-offcanvas mm-right ${!isOther ? "mm-current" : ""} mm-next mm-hasnavbar-top-1${isAuthMobileSideBar ? "mm-current mm-opened mm-opening" : ""
        }`}
    >

      <div className="mm-navbar mm-navbar-top mm-navbar-top-1 mm-navbar-size-1 mm-hasbtns"><a className="mm-prev mm-btn mm-hidden"></a>
        <span
          onClick={handleClose}
          style={{
            cursor: 'pointer'
          }}
          className="mm-close mm-btn" href="#root"></span>
      </div>
      <div className="mm-panels">
        <div className="mm-panel mm-opened mm-current" id="mm-2" style={{ marginTop: '3rem' }}>
          <div className="mm-navbar mm-hidden"><a className="mm-title">Menu</a></div>

          {/* *************************** logged in listing <ul> </ul> ***************************/}
          {localStorage.getItem("token") ?
            <ul className="mm-listview">
              <li>
                <span className="nav-mobile-user-info">
                  <span className="clear">
                    <b className="js-username">{userData && userData.username}</b>
                    <span className="btn-refresh">
                      {/* <i className="fa fa-refresh"></i> */}
                    </span>
                  </span>

                  <span className="clear">
                    <b>Cash:</b>
                    <span className="js-balance-cash">TND {balance ? balance : <span className="balance-spinner"></span>}</span>
                    {/* <span className="js-balance-cash">TND  <span className="balance-spinner-mobile"></span></span> */}
                  </span>

                  <span className="clear">
                    <b>Cashback:</b>
                    <span className="js-sports-bonus">
                      <div onClick={() => setConvertCashback(true)}>
                        TND {cashback ? cashback : <span className="balance-spinner"></span>}
                        <BiRefresh size={20} color={"#008746"} />
                      </div>
                    </span>

                  </span>

                </span>
              </li>
              <li>
                <Link to='/my-account' state={token ? { activeTab: 'account' } : {}}>
                  <FaUserAlt className='icon-re' size={15} color={"#008746"} />
                  My account
                </Link>
              </li>
              <li>
                <Link to='/my-account' state={token ? { activeTab: 'change_password' } : {}}>
                  <FaKey className='icon-re' size={15} color={"#008746"} />
                  Change Password
                </Link>
              </li>
              <li>
                <Link to='/my-account' state={token ? { activeTab: 'transactions' } : {}}>
                  <FaListAlt className='icon-re' size={15} color={"#008746"} />
                  Transactions
                </Link>
              </li>
              <li>
                <Link to='/my-account' state={token ? { activeTab: 'casino_history' } : {}}>
                  <FaHistory className='icon-re' size={15} color={"#008746"} />
                  Casino History
                </Link>
              </li>
              <li style={{
                cursor: 'pointer'
              }} onClick={() => {
                socket.disconnect()
                localStorage.clear()
              }}>
                <a className="btn-logout" href="/" title="Logout">
                  <FaPowerOff className='icon-re' size={15} color={"#008746"} />
                  Logout			</a>
              </li>
            </ul>
            :
            <ul className="mm-listview">
              <li>
                <span className="nav-mobile-user-login">
                  <form className="form-inline form-login flex-lg-nowrap mb-2">
                    <div className="input-group on-dark mr-lg-2 mb-lg-0 mb-2">
                      <span className="input-group-addon">
                        <FaUser className='icon-re' size={15} color={"#008746"} />

                      </span>
                      <input onChange={handleUsername} type="text" className="form-control login-username" placeholder="Username" required="" />
                    </div>

                    <div className="input-group on-dark mr-lg-2 mb-lg-0 mb-2">
                      <span className="input-group-addon">
                        <FaLock className='icon-re' size={15} color={"#008746"} />
                      </span>
                      <input onChange={handlePassword}
                        type={!passwordplain ? 'password' : 'text'}
                        className="form-control login-password"
                        placeholder="Password"
                        required="" />

                      <span className="input-group-addon" onClick={togglePasswordText}>
                        {!passwordplain ?
                          <FaEye className='icon-re' size={15} color={"#008746"} />
                          :
                          <FaEyeSlash className='icon-re' size={15} color={"#008746"} />
                        }
                      </span>
                    </div>
                    <button onClick={handleLogin}
                      className={`btn btn-primary mr-sm-2 mb-sm-0 btn-login-form ${loading && "btn-loading"}`}
                      data-original-title="" title=""
                      aria-describedby='popover333333'
                    >Login</button>
                    {error &&
                      <div className="tooltip-login-mobile">
                        <span className="tooltiptext">Wrong Credentials</span>
                      </div>
                    }
                  </form>
                </span>
              </li>
            </ul>
          }
        </div>
      </div>
    </nav>
  )
}

export default MobileAuthNav
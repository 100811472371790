import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_LOADING_ENABLE,
  LOGIN_LOADING_DISABLE,
} from "../types";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const authentification = (body) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_LOADING_ENABLE });
    const res = await axios.post(`${API_URL}/api/signin`, body);
    // if (res.data) {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    // toast.warning(
    //   "تنويه: سيتم إجراء أعمال الصيانة الدورية على الموقع يوم 5 مارس 2024، بين الساعة 01:00 صباحًا والساعة 05:00 صباحًا.وذلك لتحسين جودة الموقع نعتذر عن أي إزعاج قد يحدث ونشكر تفهمكم",
    //   {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   }
    // );
    // setTimeout(function () {
    //   window.location.reload();
    // }, 5000);
      window.location.reload();

    // }
  } catch (err) {
    console.log(err);
    if (err?.response?.data) {
      dispatch({
        type: LOGIN_FAILED,
        payload: err.response.data.message,
      });
      dispatch({ type: LOGIN_LOADING_DISABLE });
    } else {
      dispatch({
        type: LOGIN_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({ type: LOGIN_LOADING_DISABLE });
    }
  }
};

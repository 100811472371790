import React, { useState, useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openCasino_8,
  openFiable,
  openGapi,
  openPlaylogiq,
  openSoftswiss,
  openZippelin,
} from "../redux/actions/casino";
import { RESET_OPEN_STATE } from "../redux/types";
import NavBar from "../componenets/shared/NavBar";
import MobileAuthSidebar from "../componenets/shared/MobileAuthSidebar";
import MobileSidebar from "../componenets/shared/MobileSidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaExpand, FaWindowClose } from "react-icons/fa";
const CasinoGameModal = (props) => {
  const datenow = new Date();

  const iframeRef = useRef(null);
  let navigate = useNavigate();
  const location = useLocation();
  const gameType = location.state?.type;
  const fromNav = location.state?.from;
  const fromProvider = location.state?.fromProvider;
  const [gameUrl, setGameUrl] = useState("");
  const data = location.state?.item;
  const { iframeUrl, loading } = useSelector((state) => state.casino);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data.is_active === true) {
      dispatch(openFiable(data.url));
    } else if (data.gapi === false) {
      if (
        (data.producer == "pragmatic play" &&
          !data.identifier.includes("pragmaticexternal")) ||
        (data.producer == "pgsoft" && !data.identifier.includes("relax")) ||
        (data.producer == "amatic" && !data.gapi)
      ) {
        dispatch(openCasino_8(data));
      } else {
        dispatch(openSoftswiss(data.identifier));
      }
    } else if (data.gapi === true) {
      dispatch(openGapi(data.menu_title));
    } else if (gameType == "zippelin") {
      dispatch(openZippelin());
    }
    return () => {
      dispatch({ type: RESET_OPEN_STATE });
      setGameUrl("");
    };
  }, [data, dispatch]);

  useEffect(() => {
    handleCloseMobileSideBar(); // Close the sidebar when the location changes
  }, [location]);

  useEffect(() => {
    const handleNavigation = (event) => {
      const data = event.state;
      if (data) {
        // Do something with the state data
        console.log(data);
      }
    };

    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, []);

  useEffect(() => {
    if (iframeUrl) {
      window.location.replace(iframeUrl);
    }
  }, [iframeUrl]);

  const [isAuthMobileSideBar, setisAuthMobileSideBar] = useState(false);
  const [isSidebarOpened, setisSidebarOpened] = useState(false);

  const handleOpenAuthSidebar = () => {
    setisSidebarOpened(false);
    setisAuthMobileSideBar(true);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.add(className);
    });
  };

  const handleCloseAuthSidebar = () => {
    setisAuthMobileSideBar(false);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.remove(className);
    });
  };
  const handleOpenMobileSideBar = () => {
    setisAuthMobileSideBar(false);
    // this function will change the style of html balise style and add like the current site we clone.
    setisSidebarOpened(true);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.add(className);
    });
    // htmlElement.classList.remove('mm-right');
    // classNames.classList.toggle('mm-right',isSidebarOpened)
  };

  const handleCloseMobileSideBar = () => {
    // this function will change the style of html balise style and add like the current site we clone.
    setisSidebarOpened(false);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.remove(className);
    });
    // htmlElement.classList.remove('mm-right');
    // classNames.classList.toggle('mm-right',isSidebarOpened)
  };

  const handleToggleSidebar = () => {
    if (isSidebarOpened) {
      handleCloseMobileSideBar();
    } else {
      handleOpenMobileSideBar();
    }
  };

  function Game({ data, iframeUrl, navigate }) {
    const iframeFullToggle = () => {
      if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      }
    };
    return (
      <div className="game-modal">
        <div
          className="game-bg"
          style={{ backgroundImage: `url(${data.image})` }}
        ></div>
        <div className="single-game">
          <div className="row-wrapper single-game-wrapper">
            <div className="single-game-header">
              <h1 className="game-title">{data.label}</h1>
              <div className="game-controls">
                <span
                  className="game-controls-full"
                  onClick={iframeFullToggle}
                  style={{ cursor: "pointer" }}
                >
                  <FaExpand
                    aria-hidden
                    className="icon-re mr-2"
                    size={32}
                    color={"#dc9125"}
                  />
                </span>
                <span>
                  <Link to={fromNav}>
                    <FaWindowClose
                      className="icon-re"
                      size={32}
                      color={"#dc9125"}
                    />
                  </Link>
                </span>
              </div>
            </div>
            <div className="single-game-main">
              <div className="single-game-frame">
                <iframe
                  id="iframe"
                  src={
                    data.label == "Zippelin" ? "https:" + iframeUrl : iframeUrl
                  }
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  allowFullScreen
                  onLoad={() => console.log("iframe loaded")}
                  ref={iframeRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function Footbar() {
    return (
      // <div className="footbar">
      //   <div className="footbar-search">
      //     <form id="gamelist-search-form">
      //       <input type="search" name="gamelist-search-input" id="gamelist-search-input" placeholder="Search all games" autoComplete="off" spellCheck="false" dir="auto" className="typeahead tt-input" style={{ position: 'relative', verticalAlign: 'top', backgroundColor: 'transparent' }} />
      //     </form>
      //   </div>
      //   <div className="footbar-last">
      //     <h3 className="footbar-last-title">Last Games</h3>
      //     <div className="footbar-last-games">
      //       <div>
      //         <a href="#">
      //           <img src={data.logo_url} width="80" height="80" alt={data.label} />
      //         </a>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="footbar-right">
      //     <div className="footbar-time">{moment(datenow).format('hh:mm')}</div>
      //   </div>
      // </div>
      <></>
    );
  }

  function GamePage({ data, iframeUrl, navigate }) {
    return (
      <section id="section-game" className="section-game">
        <Game data={data} iframeUrl={iframeUrl} navigate={navigate} />
        <Footbar />
      </section>
    );
  }

  function ModalPage() {
    return (
      <div
        className="modal fade modal-page"
        id="modal-page"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="row">
                <div className="col-md-11">
                  <h5 className="modal-title"></h5>
                </div>
                <div className="col-md-1">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <></>
    // <Fragment>
    //   <MobileAuthSidebar
    //     isOther={isSidebarOpened}
    //     isAuthMobileSideBar={isAuthMobileSideBar}
    //     handleClose={handleCloseAuthSidebar}
    //   />
    //   <MobileSidebar
    //     isOther={isAuthMobileSideBar}
    //     isOpen={isSidebarOpened}
    //     handleClose={handleCloseMobileSideBar}
    //   />
    //   <div id="mm-0" className="mm-page mm-slideout">
    //     <div>
    //       <NavBar
    //         isAuthMobileSideBar={isAuthMobileSideBar}
    //         isSidebarOpened={isSidebarOpened}
    //         handleOpenAuth={handleOpenAuthSidebar}
    //         handleCloseAuth={handleCloseAuthSidebar}
    //         handleOpenSidebar={handleOpenMobileSideBar}
    //         handleCloseSidebar={handleCloseAuthSidebar}
    //       />
    //       {/* <GamePage data={data} iframeUrl={iframeUrl} navigate={navigate} /> */}
    //     </div>
    //     <div id="overlay">
    //       <span className="game-spinner">
    //       </span>
    //     </div>
    //     <ModalPage />
    //   </div>
    // </Fragment>
  );
};
export default CasinoGameModal;

import React from "react"

const LoadMore = ({ page, setPage,loading }) => {
    const handleLoadMore = (e) => {
        e.preventDefault();
        setPage(page + 1);
        // window.scrollTo(0, 0);
    };
    return (
        <div className="text-center mt-3">
            <button type="button" className={`btn btn-primary ${loading && "btn-loading"}`} onClick={handleLoadMore}> Load More Games </button>
        </div>
    );
};
export default LoadMore;
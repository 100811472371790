import {
  GET_HOME_GAMES_EN_SPINNER,
  GET_HOME_GAMES_SUCCESS,
  GET_HOME_GAMES_DIS_SPINNER,
  GET_HOME_GAMES_FAILED,
  GET_CATEGORY_EN_SPINNER,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_DIS_SPINNER,
  GET_CATEGORY_FAILED,
  GET_PROVIDERS_EN_SPINNER,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_DIS_SPINNER,
  GET_PROVIDERS_FAILED,
  GET_GAMES_EN_SPINNER,
  GET_GAMES_SUCCESS,
  GET_GAMES_DIS_SPINNER,
  GET_GAMES_FAILED,
  GET_MINI_GAMES_EN_SPINNER,
  GET_MINI_GAMES_SUCCESS,
  GET_MINI_GAMES_DIS_SPINNER,
  GET_MINI_GAMES_FAILED,
  GET_TOP_GAMES_EN_SPINNER,
  GET_TOP_GAMES_SUCCESS,
  GET_TOP_GAMES_DIS_SPINNER,
  GET_TOP_GAMES_FAILED,
  RESET_PROVIDER_STATE,
  RESET_CATEGORIES_STATE,

  RESET_HOMEGAMES_STATE,
  RESET_GAMES_STATE,
  OPEN_SUCCESS,
  OPEN_FAILED,
  OPEN_LOADING_ENABLE,
  OPEN_LOADING_DISABLE,
  RESET_OPEN_STATE
} from "../types";
const initialState = {
  providers: null,
  category: null,
  games: null,
  topGames: null,
  gamesbycategory: null,
  getHomeGamesIsError: null,
  getHomeGamesError: null,
  loading: false,
};

const casinoReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PROVIDER_STATE:
      return {
        ...state,
        providers: null
      }
    case RESET_CATEGORIES_STATE:
      return {
        ...state,
        category: null
      }
    case RESET_HOMEGAMES_STATE:
      return {
        ...state,
        gamesbycategory: null
      }
    case RESET_GAMES_STATE:
      return {
        ...state,
        games: null
      }
    case RESET_OPEN_STATE:
      return {
        ...state,
        iframeUrl: null,
        isError: false,
        error: null,
      }

    case GET_HOME_GAMES_SUCCESS:
      return {
        ...state,
        gamesbycategory: action.payload,
        getHomeGamesIsError: false,
        getHomeGamesError: null,
      };
    case GET_HOME_GAMES_EN_SPINNER:
      return { ...state, loading: true };
    case GET_HOME_GAMES_DIS_SPINNER:
      return { ...state, loading: false };
    case GET_HOME_GAMES_FAILED:
      return {
        ...state,
        getHomeGamesIsError: true,
        getHomeGamesError: action.payload,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
        getCategoryIsError: false,
        getCategoryError: null,
      };
    case GET_CATEGORY_EN_SPINNER:
      return { ...state, loading: true };
    case GET_CATEGORY_DIS_SPINNER:
      return { ...state, loading: false };
    case GET_CATEGORY_FAILED:
      return {
        ...state,
        getCategoryIsError: true,
        getCategoryError: action.payload,
      };
    case GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.payload,
        getProvidersIsError: false,
        getProvidersError: null,
      };
    case GET_PROVIDERS_EN_SPINNER:
      return { ...state, loading: true };
    case GET_PROVIDERS_DIS_SPINNER:
      return { ...state, loading: false };
    case GET_PROVIDERS_FAILED:
      return {
        ...state,
        getCategoryIsError: true,
        getCategoryError: action.payload,
      };
    case GET_GAMES_SUCCESS:
      return {
        ...state,
        games: action.payload,
        getGamesIsError: false,
        getGamesError: null,
      };
    case GET_GAMES_EN_SPINNER:
      return { ...state, loading: true };
    case GET_GAMES_DIS_SPINNER:
      return { ...state, loading: false };
    case GET_GAMES_FAILED:
      return {
        ...state,
        getGamesIsError: true,
        getGamesError: action.payload,
      };
    case GET_MINI_GAMES_SUCCESS:
      return {
        ...state,
        games: action.payload,
        getGamesIsError: false,
        getGamesError: null,
      };
    case GET_MINI_GAMES_EN_SPINNER:
      return { ...state, loading: true };
    case GET_MINI_GAMES_DIS_SPINNER:
      return { ...state, loading: false };
    case GET_MINI_GAMES_FAILED:
      return {
        ...state,
        getGamesIsError: true,
        getGamesError: action.payload,
      };



    case GET_TOP_GAMES_SUCCESS:
      return {
        ...state,
        topGames: action.payload,
        getTopGamesIsError: false,
        getTopGamesError: null,
      };
    case GET_TOP_GAMES_EN_SPINNER:
      return { ...state, loading: true };
    case GET_TOP_GAMES_DIS_SPINNER:
      return { ...state, loading: false };
    case GET_TOP_GAMES_FAILED:
      return {
        ...state,
        getTopGamesIsError: true,
        getTopGamesError: action.payload,
      };

    case OPEN_SUCCESS:
      return {
        ...state,
        iframeUrl: action.payload,
        isError: false,
        error: null,
      };
    case OPEN_LOADING_ENABLE:
      return { ...state, loading: true };
    case OPEN_LOADING_DISABLE:
      return { ...state, loading: false };
    case OPEN_FAILED:
      return {
        ...state,
        isError: true,
        error: action.payload,
      };


    default:
      return state
  }
};

export default casinoReducer;

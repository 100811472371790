import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../redux/actions/casino";
import { RESET_CATEGORIES_STATE } from "../../redux/types";

const CategoryNavbar = ({ type, tab, setTab }) => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.casino);

  const [localCategories, setlocalCategories] = useState([]);

  // useEffect to fetch the categories
  useEffect(() => {
    dispatch(getCategory(type));
    return () => {
      dispatch({ type: RESET_CATEGORIES_STATE });
    };
  }, [dispatch, type]);

  // update localCategories when category changes
  useEffect(() => {
    setlocalCategories(category || []);
  }, [category, setlocalCategories]);

  // memoize the setTab function to avoid unnecessary re-renders
  const handleTabChange = useCallback(
    (category) => {
      setTab(category);
    },
    [setTab]
  );


  // render the categories
  const categories = useMemo(() => {
    return [
      // <li key="all-category" style={{ cursor: "pointer" }} className="nav-item">
      //   <div
      //     onClick={() => handleTabChange("")}
      //     // data-id="-1"
      //     title="All Games"
      //     data-badge=""
      //     className={`horizontal-sl-item-bc accordion-button ${
      //       tab === "" ? "active" : ""
      //     }`}
      //   >
      //     <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-all-games"></i>
      //     <span className="horizontal-sl-title-bc">All Games</span>
      //   </div>
      // </li>,

      // cat.map((item, idx) => (
      //   <div
      //     onClick={() => handleTabChange(item.category)}
      //     key={`cateogry-item-${idx}`}
      //     data-badge=""
      //     className={`horizontal-sl-item-bc accordion-button ${
      //       tab === item?.category ? "active" : ""
      //     }`}
      //     style={{ cursor: "pointer" }}
      //   >
      //     <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-all-games"></i>

      //     <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-topslots"></i>
      //     <span className="horizontal-sl-title-bc">{item?.category}</span>
      //   </div>
      // )),

      <div
        id="filterSwiper"
        className="casino-filter-swiper swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios"
      >
        <ul className="list-inline swiper-wrapper" style={{ minHeight: "auto" }}>
          <li
            className={`swiper-slide ng-star-inserted swiper-slide-next  ${
              tab === "" ? "active" : ""
            }`}
            onClick={() => handleTabChange("")}
          >
            <div className="d_flex">
            <i class="cd-icon icon-casino-filter-10"></i>
            <span>All Games</span>
            </div>
          </li>
          {localCategories.map((item, idx) => (
            <li
              className={`swiper-slide ng-star-inserted swiper-slide-next  ${
                       tab === item?.category ? "active" : ""
                     }`}
              onClick={() => handleTabChange(item.category)}
            >
              <div className="d_flex">
                <i className="cd-icon icon-casino-filter-18"></i>
                <span> {item.category}</span>
              </div>
            </li>
          ))}
        </ul>
        <span
          className="swiper-notification"
          aria-live="assertive"
          aria-atomic="true"
        ></span>
      </div>,
    ];
  }, [handleTabChange, localCategories, tab]);

  return (
    <div
      style={{ alignItems: "center" }}
      className="casino-category-chooser-container"
    >
      <div className="horizontal-sl-list casino-horizontal-sl-list-bc horizontal-items-expanded scroll-start ">
        <i className="horizontal-sl-arrow bc-i-small-arrow-left"></i>
        <div
          style={{ transform: "translateX(0px)"}}
          className="horizontal-sl-wheel"
        >
          {categories}
        </div>
        <i className="horizontal-sl-arrow bc-i-small-arrow-right"></i>
      </div>
    </div>
  );
};

export default CategoryNavbar;

import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCasinoHistory } from '../../redux/actions/user';

const CasinoHistory = ({ activeTab }) => {
    const [page, setpage] = useState(0);
    const [localCasino, setlocalCasino] = useState([])
    const dispatch = useDispatch();
    const token = localStorage.getItem("token") ?? null;
    const {
        casino_loading,
        // transfer_error,
        casino
    } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getCasinoHistory(token, 0))
        return () => {
            setpage(0)
            setlocalCasino([])
        }
    }, [])

    useEffect(() => {
        if (casino && casino.items) {
            setpage(casino.currentPage)
            if (page === 0) {
                setlocalCasino(casino.items)
            } else {
                setlocalCasino([...localCasino, ...casino.items])
            }
        }
    }, [casino])

    const handlePagination = useCallback(() => {
        dispatch(getCasinoHistory(token, page))
    }, [page])

    useEffect(() => {
        handlePagination();
    }, [handlePagination])
    return (
        <div className="col-xl-9 col-lg-9 col-12" style={{ display: activeTab === 'casino_history' ? 'block' : 'none' }}>
            <div className="content-main touch-left">
                <h3 className="page-title mb-4">
                    Casino History
                </h3>
                <div id="transactions-betting">
                    <div className="transaction-filters">
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <div className="input-group mb-0">
                                    <span className="input-group-addon">
                                        <i className="fa fa-calendar-o"></i>
                                    </span>
                                    <input type="date" placeholder="Start Date" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="input-group mb-0">
                                    <span className="input-group-addon">
                                        <i className="fa fa-calendar-o">
                                        </i>
                                    </span>
                                    <input type="date" placeholder="End Date" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-2 col-12">
                                <button className="btn btn-primary w-100">
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="VueTables__table table table-striped table-bordered table-hover">
                            <thead>
                                <th className="VueTables__sortable ">
                                    <span title="" className="VueTables__heading">game name</span>
                                </th>
                                <th className="text-center">
                                    <span title="" className="VueTables__heading">
                                        action</span>
                                </th>
                                <th className="text-center">
                                    <span title="" className="VueTables__heading">
                                        amount</span>
                                </th>
                                <th className="text-center">
                                    <span title="" className="VueTables__heading">
                                        old balance</span>
                                </th>
                                <th className="text-center">
                                    <span title="" className="VueTables__heading">
                                        new balance</span>
                                </th>
                                <th className="text-center">
                                    <span title="" className="VueTables__heading">
                                        date</span>
                                </th>
                            </thead>
                            <tbody>
                                {casino_loading ?
                                    <div>LOADING</div>
                                    :
                                    localCasino && localCasino.map((item, idx) => {
                                        return (
                                            <tr className="VueTables__no-results" key={idx}>
                                                <td className="text-center">{item.game_identifier}</td>
                                                <td className="text-center">{item.action}</td>
                                                <td className="text-center">{item.amount}</td>
                                                <td className="text-center">{item.old_balance}</td>
                                                <td className="text-center">{item.new_balance}</td>
                                                <td className="text-center"> {item.createdAt} </td>
                                            </tr>
                                        )
                                    })
                                }
                                {casino && casino.totalPages && casino.totalPages - 1 > casino.currentPage ?
                                    <tr className="VueTables__no-results">

                                        <td colspan="6" class="text-center">
                                            <div onClick={() => {
                                                setpage(page + 1)
                                            }} className='btn btn-primary'>Show more</div>
                                        </td>

                                    </tr>
                                    : <></>}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CasinoHistory
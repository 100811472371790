import React, { memo } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import Hot from "../../assets/images/hot.svg";

const GameCard = memo(({ item, setOpenLogin, provider }) => {
  const placeholderImage =
    "https://img.freepik.com/premium-vector/casino-mascot-logo-esport-template_142989-365.jpg?w=2000";

  const token = localStorage.getItem("token") ?? null;
  const renderGameImage = () => {
    const imageSrc = item.gapi ? item.game_image : item.image;

    return (
      <LazyLoadImage
        className="LazyLoad"
        effect="blur"
        src={imageSrc}
        alt={item.gapi ? item.name : item.title}
        placeholderSrc={imageSrc}
        onError={(e) => {
          e.target.src = placeholderImage;
        }}
      />
    );
  };

  return (
    <Link
      to={token ? "/open" : ""}
      state={token ? { item, type: "casino",from:"/casino", fromProvider: provider } : {}}
      className="col-xl-2 col-lg-3 col-6">
      <div data-id="86" className="item-game">
        {item.is_featured == true ? (
          <span className="item-game-badge item-game-badge-hot">
            <img src={Hot} alt="hot" />
          </span>
        ):<></>}
        <figure className="item-game-thumb">{renderGameImage()}
          <div title="Play Now" style={{
            border: "1px solid #eee"
          }} className="item-game-launch">
          <span onClick={()=> {
              if (!token) {
              setOpenLogin(true)
              }
          }}>{!token ? "Sign In" : "PLAY NOW"}</span>
          </div>
        </figure>
        <div className="item-game-desc">
          <p className="item-game-title">
            {item.gapi ? (
              <a href="#">{item.name?.length > 20 ? item.name.substring(0, 19) + " ..." : item?.name}</a>
            ) : (
              <a href="#">{item?.title?.length > 20 ? item.title.substring(0, 19) + " ..." : item?.title}</a>
            )}
          </p>
        </div>
      </div>


    </Link>

   
  );
});

export default GameCard;

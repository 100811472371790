import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMiniGames } from "../../redux/actions/casino";
import { RESET_GAMES_STATE } from "../../redux/types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import Zippelin from "../../assets/images/zippelin.webp";

const GamesList = ({ setOpenLogin }) => {
  const dispatch = useDispatch();
  const { games, loading } = useSelector((state) => state.casino);

  useEffect(() => {
    dispatch(getMiniGames());

    return () => {
      dispatch({ type: RESET_GAMES_STATE });
    };
  }, []);
  const placeholderImage =
    "https://img.freepik.com/premium-vector/casino-mascot-logo-esport-template_142989-365.jpg?w=2000";

  const token =
    localStorage.getItem("token") !== undefined &&
    localStorage.getItem("token") !== null
      ? localStorage.getItem("token")
      : null;
  const onImageError = (e) => {
    e.target.src = placeholderImage;
  };
  return (
    <div className="row row-game-listing mt-5 mr-0 ml-0">
      <Link
        to={token ? "/open" : ""}
        state={
          token
            ? {
                item: { identifier: "spribe:aviator", gapi: false },
                type: "casino",
                from: "/mini-games",
              }
            : {}
        }
        className="col-xl-3 col-lg-4 col-6"
      >
        <div className="item-game">
          <figure className="item-game-thumb">
            <span>
              <LazyLoadImage
                className="LazyLoad"
                effect="blur"
                src={"https://cdn.softswiss.net/i/s3/spribe/aviator.png"}
                onError={onImageError}
                placeholderSrc={
                  "https://cdn.softswiss.net/i/s3/spribe/aviator.png"
                }
              />
            </span>
            <span title="Play Now" className="btn btn-primary item-game-launch">
              <span>Play Now</span>
            </span>
          </figure>{" "}
          <div className="item-game-desc">
            <p className="item-game-title">
              <a href="#">Aviator</a>
            </p>
          </div>
        </div>
      </Link>
      <Link
        to={token ? "/open" : ""}
        state={
          token
            ? {
                item: { image: Zippelin, label: "Zippelin" },
                type: "zippelin",
                from: "/mini-games",
              }
            : {}
        }
        className="col-xl-3 col-lg-4 col-6"
      >
        <div className="item-game">
          <figure className="item-game-thumb">
            <span>
              <LazyLoadImage
                className="LazyLoad"
                effect="blur"
                src={Zippelin}
                onError={onImageError}
                placeholderSrc={Zippelin}
              />
            </span>
            <span title="Play Now" className="btn btn-primary item-game-launch">
              <span>Play Now</span>
            </span>
          </figure>{" "}
          <div className="item-game-desc">
            <p className="item-game-title">
              <a href="#">Zippelin</a>
            </p>
          </div>
        </div>
      </Link>
      <Link
        to={token ? "/open" : ""}
        state={
          token
            ? {
                item: { identifier: "pragmaticexternal:Spaceman", gapi: false },
                type: "casino",
                from: "/mini-games",
              }
            : {}
        }
        className="col-xl-3 col-lg-4 col-6"
      >
        <div className="item-game">
          <figure className="item-game-thumb">
            <span>
              <LazyLoadImage
                className="LazyLoad"
                effect="blur"
                src={
                  "https://cdn.softswiss.net/i/s3/pragmaticexternal/Spaceman.png"
                }
                onError={onImageError}
                placeholderSrc={
                  "https://cdn.softswiss.net/i/s3/pragmaticexternal/Spaceman.png"
                }
              />
            </span>
            <span title="Play Now" className="btn btn-primary item-game-launch">
              <span>Play Now</span>
            </span>
          </figure>{" "}
          <div className="item-game-desc">
            <p className="item-game-title">
              <a href="#">Spaceman</a>
            </p>
          </div>
        </div>
      </Link>
      {(games !== null && games?.length > 0) &&
        games?.map((itemF, idx) => (
          <Link
            to={token ? "/open" : ""}
            state={
              token ? { item: itemF, type: "fiable", from: "/mini-games" } : {}
            }
            key={idx.toString()}
            className="col-xl-3 col-lg-4 col-6"
          >
            <div className="item-game">
              <figure className="item-game-thumb">
                <span>
                  <LazyLoadImage
                    className="LazyLoad"
                    effect="blur"
                    src={itemF.image_name}
                    onError={onImageError}
                    alt={itemF.name}
                    placeholderSrc={itemF.image_name}
                  />
                </span>
                <a
                  href="#"
                  title="Play Now"
                  className="btn btn-primary item-game-launch"
                >
                  <span
                    onClick={() => {
                      if (!token) {
                        setOpenLogin(true);
                      }
                    }}
                  >
                    {!token && "Login &"} Play Now{" "}
                  </span>
                </a>
              </figure>{" "}
              <div className="item-game-desc">
                <p className="item-game-title">
                  <a href="#">{itemF.name}</a>
                </p>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
};
export default GamesList;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProviders } from "../../redux/actions/casino";
import { RESET_PROVIDER_STATE } from "../../redux/types";
import OrderByFilter from "./Filters/OrderByFilter";
import SearchFilter from "./Filters/SearchFilter";

const Filter = ({ type, provider, setProvider, searchAny, setSearchAny }) => {
  const [localProviders, setlocalProviders] = useState([]);
  const [orderBy, setorderBy] = useState("popularity");
  const dispatch = useDispatch();
  const { providers } = useSelector((state) => state.casino);
  // fetch providers only on mount
  useEffect(() => {
    dispatch(getProviders(type));
    return () => {
      dispatch({ type: RESET_PROVIDER_STATE });
      setlocalProviders([]);
    };
  }, []);

  // memoize localProviders
  const memoizedLocalProviders = useMemo(() => providers || [], [providers]);
  useEffect(() => {
    setlocalProviders(memoizedLocalProviders);
  }, [memoizedLocalProviders]);
  // use callback to memoize handleChangeProvider
  const handleChangeProvider = useCallback(
    (event) => {
      setProvider(event.target.value);
    },
    [provider]
  );
  const handleSearchChange = (e) => {
    setSearchAny(e.target.value);
  };
  const handleOrderByChange = (value) => {
    setorderBy(value);
  };
  return (
    <div className={`game-listing-filters`}>
      {/* <OrderByFilter
        orderByValue={orderBy}
        handleChange={handleOrderByChange}
      /> */}
      <div className="game-search-block__filter-button col-sm-4 col-md-4 col-12 col-lg-3 mb-3 ">
        {/* <span className="filterby-label">Filter by:</span> */}
        {/* <select
          value={provider}
          defaultValue={"all"}
          onChange={handleChangeProvider}
          id="providers-select"
          className="form-control select"
        >
          <option value="all">All Game Providers</option>
          {localProviders !== undefined &&
            localProviders !== null &&
            localProviders.length > 0 &&
            localProviders.map((item, idx) => {
              return (
                <option key={`provider-key-${idx}`}>{item.producer}</option>
              );
            })}
        </select> */}
        <div class="select-container">
          <div class="select">
            <select
              value={provider}
              defaultValue={"all"}
              onChange={handleChangeProvider}
            >
              <option value="all">All Game Providers</option>
              {localProviders !== undefined &&
                localProviders !== null &&
                localProviders.length > 0 &&
                localProviders.map((item, idx) => {
                  return (
                    <option key={`provider-key-${idx}`}>{item.producer}</option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <SearchFilter searchAny={searchAny} handleChange={handleSearchChange} />
    </div>
  );
};

export default Filter;

import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import bibeetLogo from "../../assets/images/new_logo_green.png";
import NewLogo from "../../assets/images/new_ico.png";

const MobileSidebar = ({ isOpen, handleClose, isOther }) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <nav
        id="nav-mobile"
        className={`mm-menu mm-effect-listitems-slide mm-shadow-page mm-offcanvas mm-next ${
          !isOther ? "mm-current" : ""
        } mm-hasnavbar-top-1${
          isOpen ? " mm-current mm-opened mm-opening" : ""
        }`}
      >
        <div className="mm-navbar mm-navbar-top mm-navbar-top-1 mm-navbar-size-1 mm-hasbtns">
          <span className="mm-prev mm-btn mm-hidden"></span>
          <span
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
            className="mm-close mm-btn"
            href="#mm-0"
          ></span>
        </div>
        <div className="mm-panels">
          <div className="mm-panel mm-opened mm-current" id="mm-1">
            <div className="mm-navbar mm-hidden">
              <span className="mm-title">Menu</span>
            </div>
            <ul className="mm-listview">
              <li>
                <span className="nav-mobile-logo">
                  <img
                    className="bibeet-mobile-sidebar"
                    src={bibeetLogo}
                    alt="bibeet"
                  />
                </span>
              </li>
              {/* {localStorage.getItem("user") &&
                +JSON.parse(localStorage.getItem("user")).full_id_user <
                  47887 && (
                  <li>
                    <span
                      onClick={() => {
                        handleClose();
                        navigate("/old");
                      }}
                      className=""
                    >
                      Old Sports Betting
                    </span>
                  </li>
                )} */}
              <li>
                <span
                  onClick={() => {
                    handleClose();
                    navigate("/betting");
                  }}
                  className=""
                >
                  Sports Betting
                  <img src={NewLogo} height={35} width={35} />
                </span>
              </li>

              <li>
                <span
                  onClick={() => {
                    handleClose();
                    navigate("/betting/live");
                  }}
                  className=""
                >
                  Live Betting
                  <img src={NewLogo} height={35} width={35} />
                </span>
              </li>

              <li>
                <span
                  onClick={() => {
                    handleClose();
                    navigate("/casino");
                  }}
                  className=""
                >
                  Casino
                </span>
              </li>

              <li>
                <span
                  onClick={() => {
                    handleClose();
                    navigate("/live-casino");
                  }}
                  className=""
                >
                  Live Casino
                </span>
              </li>

              <li>
                <span
                  onClick={() => {
                    handleClose();
                    navigate("/mini-games");
                  }}
                  className=""
                >
                  Mini Games
                </span>
              </li>

              {/* <li>
                <a href='/betting/virtual' className="">
                  Virtuals				</a>
              </li> */}
              <li>
                <span
                  onClick={() => {
                    handleClose();
                    navigate("/virtual");
                  }}
                  className=""
                >
                  Virtual Games
                </span>
              </li>
              {/* 
              <li>
                <span onClick={() => handleClose()} className="">
                  About Us
                </span>
              </li>

              <li>
                <span onClick={() => handleClose()} className="">
                  Promotions
                </span>
              </li>

              <li>
                <span onClick={() => handleClose()} className="">
                  Affiliates
                </span>
              </li>

              <li>
                <span onClick={() => handleClose()} className="">
                  Customer Service
                </span>
              </li>

              <li>
                <span onClick={() => handleClose()} className="">
                  Terms &amp; Conditions
                </span>
              </li>

              <li>
                <span onClick={() => handleClose()} className="">
                  Terms of Use
                </span>
              </li>

              <li>
                <span onClick={() => handleClose()} className="">
                  Privacy Policy
                </span>
              </li>

              <li>
                <span onClick={() => handleClose()} className="">
                  Responsible Gaming
                </span>
              </li>
*/}
              <li>
                <span
                  className="mm-next mm-fullsubopen"
                  href="#mm-2"
                  data-target="#mm-2"
                ></span>
                <span className="Spacer">
                  <i className="fa fa-language"></i>
                  English
                </span>
              </li>
            </ul>
          </div>
          <div className="mm-panel mm-hidden" id="mm-2">
            <div className="mm-navbar mm-hidden">
              <span
                className="mm-btn mm-prev"
                href="#mm-1"
                data-target="#mm-1"
              ></span>
              <span className="mm-title" href="#mm-1">
                English
              </span>
            </div>
            <ul className="mm-listview">
              <li>
                <span>French </span>
              </li>
              <li>
                <span>عربى </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};
export default MobileSidebar;

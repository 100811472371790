import React, { useState, Fragment, useEffect } from "react"
import CategoryNavbar from "../componenets/casino/CategoryNavbar";
import GamesList from "../componenets/Virtual/GameList";
import Footer from "../componenets/shared/Footer";
import MobileAuthSidebar from "../componenets/shared/MobileAuthSidebar";
import MobileSidebar from "../componenets/shared/MobileSidebar";
import NavBar from "../componenets/shared/NavBar";
import LoginModal from "../componenets/loginModal/LoginModal";
import { useLocation } from "react-router-dom";

const VirtualGames = () => {
  const [openLogin,setOpenLogin] = useState(false)
  const [isAuthMobileSideBar, setisAuthMobileSideBar] = useState(false)
  const [isSidebarOpened, setisSidebarOpened] = useState(false)
  const [tab, setTab] = useState('')
  const [provider, setProvider] = useState('')
  const location = useLocation();
  useEffect(() => {
    handleCloseMobileSideBar(); // Close the sidebar when the location changes
  }, [location]);
  const handleOpenAuthSidebar = () => {
    setisSidebarOpened(false);
    setisAuthMobileSideBar(true);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening"
    ];
    classNames.forEach(className => {
      htmlElement.classList.add(className);
    });
  };

  const handleCloseAuthSidebar = () => {
    setisAuthMobileSideBar(false);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening"
    ];
    classNames.forEach(className => {
      htmlElement.classList.remove(className);
    });
  };
  const handleOpenMobileSideBar = () => {
    // this function will change the style of html balise style and add like the current site we clone.
    setisAuthMobileSideBar(false);
    setisSidebarOpened(true);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening"
    ];
    classNames.forEach(className => {
      htmlElement.classList.add(className);
    });
    // htmlElement.classList.remove('mm-right');
    // classNames.classList.toggle('mm-right',isSidebarOpened)
  };
  
  const handleCloseMobileSideBar = () => {
    // this function will change the style of html balise style and add like the current site we clone.
    setisSidebarOpened(false);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening"
    ];
    classNames.forEach(className => {
      htmlElement.classList.remove(className);
    });
    // htmlElement.classList.remove('mm-right');
    // classNames.classList.toggle('mm-right',isSidebarOpened)
  };

  const handleToggleSidebar = () => {
    if (isSidebarOpened) {
      handleCloseMobileSideBar();
    } else {
      handleOpenMobileSideBar();
    }
  }

  

  return (
    <Fragment>
    <LoginModal 
      openLogin={openLogin}
      setOpenLogin={setOpenLogin}
    />
    <MobileAuthSidebar
      isOther={isSidebarOpened}
      isAuthMobileSideBar={isAuthMobileSideBar}
      handleClose={handleCloseAuthSidebar}
    />
    <MobileSidebar 
      isOther={isAuthMobileSideBar}
      isOpen={isSidebarOpened}
      handleClose={handleCloseMobileSideBar}
    />
 
          <div id="root" className="mm-page mm-slideout" style={{ minHeight: isAuthMobileSideBar && '606px' }}>
          <NavBar 
            isAuthMobileSideBar={isAuthMobileSideBar}
            isSidebarOpened={isSidebarOpened}
            handleOpenAuth={handleOpenAuthSidebar}
            handleCloseAuth={handleCloseAuthSidebar}
            handleOpenSidebar={handleOpenMobileSideBar}
            handleCloseSidebar={handleCloseAuthSidebar}
            handleToggleSidebar={handleToggleSidebar}
            activeNav={'VirtualGames'} 
          />
           <div class="header-indecator"></div>
        <div className="container">
          <section id="section-games" className="section-games">
            <CategoryNavbar type={'virtual'} tab={tab} setTab={setTab} />
            <div className="wrap-game-listing">
              <GamesList 
                openLogin={openLogin}
                setOpenLogin={setOpenLogin}
              type={'virtual'} category={tab} provider={provider} setProvider={setProvider} />
            </div>
          </section>
          
        </div>
      </div>
      <Footer />
    </Fragment>


  );
}
export default VirtualGames;

import React,{ useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails } from '../../redux/actions/user';

const AccountDetails = ({activeTab}) => {
  const token = localStorage.getItem("token") ?? null;
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.user);
  useEffect(() => {
      dispatch(getUserDetails(token))
  }, [])
  
  return (
    <div class="col-xl-9 col-lg-9 col-12" style={{ display: activeTab === 'account' ? 'block' : 'none' }}>
    <div class="content-main touch-left">
        <h3 class="page-title">My account</h3>

        <form action="/" class="form form-user-details">
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-first-name" class="form-control-label">
                            First name									</label>
                        <input 
                            type="text" 
                            id="user-first-name" 
                            name="firstname" 
                            value={user && user.firstname ? user.firstname : ""}
                            readonly="readonly" 
                            required="required" 
                            class="form-control" 
                        />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-last-name" class="form-control-label">
                            Last name									</label>
                        <input 
                            type="text" 
                            id="user-last-name" 
                            name="lastname"
                            value={user && user.lastname ? user.lastname : ""}
                            readonly="readonly" 
                            required="required" 
                            class="form-control" 
                        />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-email" class="form-control-label">
                            Email									</label>
                        <input 
                            type="email" 
                            id="user-email" 
                            name="email" 
                            value={user && user.email ? user.email : ""}
                            readonly="readonly" 
                            required="required" 
                            class="form-control" 
                        />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-birthday" class="form-control-label">Birthday</label>
                        <input type="text" id="user-birthday" value="" readonly="readonly" required="required" class="form-control" />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-country" class="form-control-label">
                            Country									</label>
                        <input type="text" id="user-country" value="" readonly="readonly" required="required" class="form-control" />
                        <input type="hidden" name="country" value="TN" />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-city" class="form-control-label">
                            City									</label>
                        <input type="text" id="user-city" name="city" value="" readonly="readonly" class="form-control" />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-street" class="form-control-label">
                            Address									</label>
                        <input type="text" id="user-street" name="address" value="" readonly="readonly" class="form-control form-control-danger" />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-zip" class="form-control-label">
                            Zip Code									</label>
                        <input type="text" id="user-zip" name="zipCode" value="" readonly="readonly" class="form-control" />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-phone" class="form-control-label">
                            Phone Number										
                            <small class="text-muted">with country code</small>
                        </label>
                        <input type="tel" id="user-phone"  readonly="readonly" name="phoneNumber" placeholder="" required="required" 
                        value={user && user.phone ? user.phone : ""}
                        class="form-control" />
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="user-id" class="form-control-label">
                            Player ID                                    </label>
                        <input type="text" id="user-id" name="user_id" value="" readonly="readonly" required="required" class="form-control" />
                    </div>
                </div>
                <div class="col-md-6 col-12 custom-checkbox">
                    <label class="custom-control custom-checkbox">
                        <input id="privacy-policy" name="emailChecked" type="checkbox" value="true" class="custom-control-input" />
                        <span class="custom-control-indicator">
                        </span>
                        <span class="custom-control-description">I want to receive marketing material via email.</span>
                    </label>
                </div>
                <div class="col-12">
                    <hr />
                    <button disabled class="no-print-data btn btn-lg btn-primary mt-3">
                        Update
                    </button>
                    <button disabled class="no-print-data pull-right btn btn-secondary btn-lg mt-3">
                        Print Data
                    </button>
                </div>
            </div>

        </form>
    </div>
</div>
  )
}

export default AccountDetails
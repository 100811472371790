import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getGames } from "../../redux/actions/casino";
import { RESET_GAMES_STATE } from "../../redux/types";
import LoadMore from "../shared/LoadMore";
import Filter from "../casino/Filter";
import GameCard from "./GameCard";

const GamesList = ({ type, provider, category, setProvider,setOpenLogin }) => {
    const dispatch = useDispatch();
    const [localGames, setlocalGames] = useState([])
    const [page, setPage] = useState(0)
    const { games, loading } = useSelector((state) => state.casino);
    const [show, setShow] = useState(false);
    const [playUrl, setPlayUrl] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [searchAny, setSearchAny] = useState([])

    const handleOpen = useCallback((play_url, game_name, logo_url) => {
        setShow(true)
        setPlayUrl(play_url)
        setImageUrl(logo_url)
    }, [])

    // we should separate the page from the filters cz we didn't emptying the localGames. 
    const handlePagination = useCallback(() => {
        dispatch(getGames(type, provider, category, page, searchAny))
    }, [page])

    // we just call handlePagination when paginate changes.
    useEffect(() => {
        handlePagination();
    }, [handlePagination])

    // this is the other filters changes.
    const handleGetGames = useCallback(() => {
        dispatch(getGames(type, provider, category, page, searchAny))
    }, [category, provider, searchAny])

    useEffect(() => {
        handleGetGames();
        return () => {
            setlocalGames([])
            dispatch({ type: RESET_GAMES_STATE })
        }
    }, [handleGetGames])

    useEffect(() => {
        if (games && games.items) {
            setPage(games.currentPage)
            if (page === 0) {
                setlocalGames(games.items)
            } else {
                setlocalGames([...localGames, ...games.items])
            }
        }
    }, [games])

    // memorized the localGames. to remove unecessary component.
    const gameCards = useMemo(() => {
        if (!localGames || !localGames.length) return [];
        return localGames.map((item, idx) => (
            <GameCard setOpenLogin={setOpenLogin} item={item} key={idx} handleOpen={handleOpen} />
        ))
    }, [localGames, handleOpen])

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {/* call the filter component, to separate code. */}
                    <Filter
                        type={'virtual'}
                        provider={provider}
                        setProvider={setProvider}
                        searchAny={searchAny}
                        setSearchAny={setSearchAny}
                    />
                    <div className="row row-game-listing">
                        {loading && <div className="game-spinner"></div>}
                        {gameCards}
                    </div>
                    {/* check if there is a pagination and call the pagination features if we had a pagination. */}
                    {games !== undefined && games !== null && page+1 < games.totalPages &&
                        <LoadMore
                            loading={loading}
                            page={page}
                            setPage={setPage}
                        />
                    }
                </div>
            </div>
        </div>
    );
}
export default GamesList;

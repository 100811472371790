import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import banner1 from "../../assets/images/banners/1.webp";
import banner2 from "../../assets/images/banners/2.webp";
import banner3 from "../../assets/images/banners/3.webp";
import banner4 from "../../assets/images/banners/4.webp";
import banner5 from "../../assets/images/banners/5.webp";
import banner6 from "../../assets/images/banners/6.webp";
import banner7 from "../../assets/images/banners/7.webp";
import banner8 from "../../assets/images/banners/8.webp";
import banner9 from "../../assets/images/banners/9.webp";
const Caroussel = () => {
  return (
    <div className="col-12 mb-1 mmobdesk phone-col12">
      <div className="sports-slider slideshow-main mt-n4 mt-n3 slick-initialized slick-slider slick-dotted">
        <div className="slick-list draggable">
          <Carousel
            autoPlay
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            style={{ borderRais: "25px" }}
          >
            <div>
              <img src={banner1} />
            </div>
            <div>
              <img src={banner2} />
            </div>
            <div>
              <img src={banner3} />
            </div>
            <div>
              <img src={banner4} />
            </div>
            <div>
              <img src={banner5} />
            </div>
            <div>
              <img src={banner6} />
            </div>
            <div>
              <img src={banner7} />
            </div>
            <div>
              <img src={banner8} />
            </div>
            <div>
              <img src={banner9} />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default Caroussel;

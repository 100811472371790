import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import BiBetLogo from "../../assets/images/new_logo_green.png"
const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="footer-top container">

        <div className="row">
          <div className="col-12 footer-top-inner">
            <div className="footer-logo">
              <img  className="bibeet-mobile" src={BiBetLogo} alt="bibeet" />
               <p>Le gain maximal est 120.000 dinars<br/></p>
            </div>

            <ul className="vertical-nav footer-nav-wrapper">
              <li className="footer-nav">
                <span onClick = {() => navigate('/terms-conditions')} title="About Us">About Us</span>
              </li>
              <li className="footer-nav">
                <span onClick = {() => navigate('/terms-conditions')} title="Promotions">Promotions</span>
              </li>
              <li className="footer-nav">
                <span onClick = {() => navigate('/terms-conditions')} title="Affiliates">Affiliates</span>
              </li>
              <li className="footer-nav">
                <span onClick = {() => navigate('/terms-conditions')} title="Customer Service">Customer Service</span>
              </li>
              <li className="footer-nav">
                <span onClick = {() => navigate('/terms-conditions')} title="Terms &amp; Conditions">Terms &amp; Conditions</span>
              </li>
              <li className="footer-nav">
                <span onClick = {() => navigate('/terms-conditions')} title="Terms of Use">Terms of Use</span>
              </li>
              <li className="footer-nav">
                <span onClick = {() => navigate('/terms-conditions')} title="Privacy Policy">Privacy Policy</span>
              </li>
              <li className="footer-nav">
                <span onClick = {() => navigate('/terms-conditions')} title="Responsible Gaming">Responsible Gaming</span>
              </li>
            </ul>

            <div className="footer-payments hidden-sm-down">
            </div>

          </div>
        </div>

      </div>

      <div className="footer-bottom-wrapper">
        <div className="footer-bottom container">
          <div className="row">
         
          </div>
        </div>
      </div>

    </footer>

  );
}
export default Footer;

import React, { useEffect,useState,useCallback } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getTransferHistory } from '../../redux/actions/user';

const Transfer = ({activeTab}) => {
  const [page, setpage] = useState(0);
  const [localTransfers, setlocalTransfers] = useState([])
  const dispatch = useDispatch();
  const token = localStorage.getItem("token") ?? null;
  const { 
    transfer_loading,
    // transfer_error,
    transfers
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getTransferHistory(token,0))
    return () => {
        setpage(0)
        setlocalTransfers([])
    }
  }, [])

//   useEffect(() => {
//     if (page > 0) {
//         dispatch(getTransferHistory(token,page))
//     }
//   }, [page])

  useEffect(() => {
    if (transfers && transfers.items) {
        setpage(transfers.currentPage)
        if (page === 0) {
            setlocalTransfers(transfers.items)
        } else {
            setlocalTransfers([...localTransfers, ...transfers.items])
        }
    }
}, [transfers])
  
const handlePagination = useCallback(() => {
    dispatch(getTransferHistory(token,page))
}, [page])

useEffect(() => {
    handlePagination();
}, [handlePagination])


  return (
    <div className="col-xl-9 col-lg-9 col-12" style={{ display: activeTab === 'transactions' ? 'block' : 'none' }}>
        <div className="content-main touch-left">
            <div id="top">

            </div>
            <h3 className="page-title">
                Transactions					
            </h3>
            <div id="transactions-financial">
                <div className="transaction-filters">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <div className="input-group mb-0">
                                <span className="input-group-addon">
                                    <i className="fa fa-calendar-o">
                                    </i>
                                </span>
                                <input type="date" placeholder="Start Date" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="input-group mb-0">
                                {/* <span className="input-group-addon">
                                    <i className="fa fa-calendar-o">
                                    </i>
                                </span> */}
                                <input type="date" placeholder="End Date" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-2 col-12">
                            <button className="btn btn-primary w-100">
                                Clear									</button>
                        </div>
                        <div className="col-md-2 ml-md-auto text-md-right">
                        </div>

                    </div>
                </div>
                <div className="show-horizontal-table">
                    <div className="VueTables VueTables--client">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-inline pull-left VueTables__search">
                                    <label for="VueTables__search_qMlOe">Filter Results:</label>
                                    <input type="text" value="" placeholder="Search query" id="VueTables__search_qMlOe" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-6">
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="VueTables__table table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th className="VueTables__sortable ">
                                            <span title="" className="VueTables__heading">Admin</span>
                                            <span className="VueTables__sort-icon pull-right  glyphicon-sort ">
                                            </span>
                                        </th>
                                        <th className="VueTables__sortable ">
                                            <span title="" className="VueTables__heading">Type</span>
                                            <span className="VueTables__sort-icon pull-right  glyphicon-sort ">
                                            </span>
                                        </th>
                                        <th className="VueTables__sortable ">
                                            <span title="" className="VueTables__heading">Player</span>
                                            <span className="VueTables__sort-icon pull-right  glyphicon-sort ">
                                            </span>
                                        </th>
                                        <th className="VueTables__sortable ">
                                            <span title="" className="VueTables__heading">Amount</span>
                                            <span className="VueTables__sort-icon pull-right  glyphicon-sort ">
                                            </span>
                                        </th>
                                        <th className="VueTables__sortable ">
                                            <span title="" className="VueTables__heading">Time</span>
                                            <span className="VueTables__sort-icon pull-right  glyphicon-sort ">
                                            </span>
                                        </th>
                                        <th className="VueTables__sortable ">
                                            <span title="" className="VueTables__heading">History</span>
                                            <span className="VueTables__sort-icon pull-right  glyphicon-sort ">
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { transfer_loading ?
                                    <div>LOADING</div>
                                    :
                                    localTransfers && localTransfers.map((item,idx)=> {
                                        return(
                                        <tr className="VueTables__no-results" key={idx}>
                                            <td className="text-center">{item.from_name}</td>
                                            <td className="text-center">{item.type}</td>
                                            <td className="text-center">{item.user_name}</td>
                                            <td className="text-center">{item.amount}</td>
                                            <td className="text-center">{item.created_at}</td>
                                            <td className="text-center">{item.history_reciver}</td>
                                        </tr>
                                        )
                                    })
                                    }
                                    {transfers && transfers.totalPages && transfers.totalPages-1 > transfers.currentPage ?
                                    <tr className="VueTables__no-results">
                                    
                                        <td colspan="6" class="text-center">
                                        <div onClick={()=>{
                                            setpage(page+1)
                                        }} className='btn btn-primary'>Show more</div>
                                        </td>
                                    
                                    </tr>
                                    :<></>}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Transfer

import React, { useState, useEffect, memo } from "react"
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";

// const GameCard = memo({ openLogin,setOpenLogin,item, idx, show, setShow  }) => {
const GameCard = memo(({ setOpenLogin,item, idx, show, setShow }) => {

    const placeholderImage =
        'https://img.freepik.com/premium-vector/casino-mascot-logo-esport-template_142989-365.jpg?w=2000'

    const onImageError = (e) => {
        e.target.src = placeholderImage
    }
    const token = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null ? localStorage.getItem('token') : null

    return (
        <>
            <Link to={token ? '/open' : ''} state={token ? { item: item, type: 'casino', from : '/live-casino' } : {}} key={idx.toString()} className="col-xl-3 col-lg-4 col-6">
                <div data-id="60" className="item-game">
                    <figure className="item-game-thumb">
                        <div >
                            <LazyLoadImage
                                className="LazyLoad"
                                effect="blur"
                                src={item.image}
                                onError={onImageError}
                                alt={item.title}
                                placeholderSrc={item.image}
                            />
                        </div>

                        <div title="Play Now" className="btn btn-primary item-game-launch">
                        <span onClick={()=> {
                            if (!token) {
                            setOpenLogin(true)
                            }
                        }}> {!token && 'Login &'} Play Now</span>
                        </div>
                    </figure> <div className="item-game-desc">
                        <p className="item-game-title">
                            <a href="#">{item.title}</a>
                        </p>
                    </div>
                </div>
            </Link>
        </>

    );
})
export default GameCard;

import React from "react";

const SearchFilter = ({ searchValue, handleChange }) => {
  return (
    // <div className="game-filters-search col-lg-3 col-md-12">
    //     <input style={{
    //         background: "#000000",
    //         color:'#fff'
    //     }} value={searchValue} onChange={handleChange} type="text" placeholder="Search" className="form-control form-control-round" />
    // </div>

    <div class="game-search-block__input-field">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        class="icon_common_search_alt game-search-block__input-search-icon"
      >
        <path
          fill="#008746"
          fill-rule="nonzero"
          d="M21.76 20.58l-5.7-5.69A7.93 7.93 0 102 9.92a7.93 7.93 0 0012.9 6.15l5.69 5.69a.83.83 0 001.18 0 .83.83 0 000-1.18zM9.92 16.17a6.26 6.26 0 11.01-12.52 6.26 6.26 0 01-.01 12.52z"
        ></path>
      </svg>
      <input
        value={searchValue}
        onChange={handleChange}
        class="game-search-block__input"
        placeholder="Rechercher"
        
      />
    </div>
  );
};

export default SearchFilter;

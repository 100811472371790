import {
  GET_HOME_GAMES_EN_SPINNER,
  GET_HOME_GAMES_SUCCESS,
  GET_HOME_GAMES_DIS_SPINNER,
  GET_HOME_GAMES_FAILED,
  GET_CATEGORY_EN_SPINNER,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_DIS_SPINNER,
  GET_CATEGORY_FAILED,
  GET_PROVIDERS_EN_SPINNER,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_DIS_SPINNER,
  GET_PROVIDERS_FAILED,
  GET_GAMES_EN_SPINNER,
  GET_GAMES_SUCCESS,
  GET_GAMES_DIS_SPINNER,
  GET_GAMES_FAILED,
  GET_MINI_GAMES_EN_SPINNER,
  GET_MINI_GAMES_SUCCESS,
  GET_MINI_GAMES_DIS_SPINNER,
  GET_MINI_GAMES_FAILED,
  GET_TOP_GAMES_EN_SPINNER,
  GET_TOP_GAMES_SUCCESS,
  GET_TOP_GAMES_DIS_SPINNER,
  GET_TOP_GAMES_FAILED,
  OPEN_SUCCESS,
  OPEN_FAILED,
  OPEN_LOADING_ENABLE,
  OPEN_LOADING_DISABLE,
} from "../types";
import axios from "axios";
import { AMATIC_URL, API_URL, PGSOFT_URL, PRAGMATIC_URL } from "../../utils/constants";

export const getHomeGames = (type_games) => async (dispatch) => {
  try {
    dispatch({
      type: GET_HOME_GAMES_EN_SPINNER,
    });
    const res = await axios.get(
      `${API_URL}/api/V2/home_games?type=${type_games}`
    );

    if (res.data) {
      dispatch({
        type: GET_HOME_GAMES_SUCCESS,
        payload: res.data.providers,
      });
      dispatch({
        type: GET_HOME_GAMES_DIS_SPINNER,
      });
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.data) {
      dispatch({
        type: GET_HOME_GAMES_FAILED,
        payload: err.response.data,
      });
      dispatch({
        type: GET_HOME_GAMES_DIS_SPINNER,
      });
    } else {
      dispatch({
        type: GET_HOME_GAMES_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({
        type: GET_HOME_GAMES_DIS_SPINNER,
      });
    }
  }
};

export const getCategory = (type_jeux) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORY_EN_SPINNER,
    });

    const res = await axios.get(`${API_URL}/api/category?type=${type_jeux}`);

    if (res.data) {
      dispatch({
        type: GET_CATEGORY_SUCCESS,
        payload: res.data.data,
      });
      dispatch({
        type: GET_CATEGORY_DIS_SPINNER,
      });
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.data) {
      dispatch({
        type: GET_CATEGORY_FAILED,
        payload: err.response.data,
      });
      dispatch({
        type: GET_CATEGORY_DIS_SPINNER,
      });
    } else {
      dispatch({
        type: GET_CATEGORY_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({
        type: GET_CATEGORY_DIS_SPINNER,
      });
    }
  }
};

export const getProviders = (type_jeux) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROVIDERS_EN_SPINNER,
    });

    const res = await axios.get(`${API_URL}/api/providers?type=${type_jeux}`);

    if (res.data) {
      dispatch({
        type: GET_PROVIDERS_SUCCESS,
        payload: res.data.data,
      });
      dispatch({
        type: GET_PROVIDERS_DIS_SPINNER,
      });
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.data) {
      dispatch({
        type: GET_PROVIDERS_FAILED,
        payload: err.response.data,
      });
      dispatch({
        type: GET_PROVIDERS_DIS_SPINNER,
      });
    } else {
      dispatch({
        type: GET_PROVIDERS_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({
        type: GET_PROVIDERS_DIS_SPINNER,
      });
    }
  }
};

export const getGames =
  (type_jeux, provider, category, page, searchAny) => async (dispatch) => {
    try {
      dispatch({
        type: GET_GAMES_EN_SPINNER,
      });
      if (provider == "all") {
        provider = "";
      }
      const res = await axios.get(
        `${API_URL}/api/games?page=${page}&size=50&provider=${provider}&category=${category}&type=${type_jeux}&name=${searchAny}`
      );

      if (res.data) {
        dispatch({
          type: GET_GAMES_SUCCESS,
          payload: res.data.data,
        });
        dispatch({
          type: GET_GAMES_DIS_SPINNER,
        });
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data) {
        dispatch({
          type: GET_GAMES_FAILED,
          payload: err.response.data,
        });
        dispatch({
          type: GET_GAMES_DIS_SPINNER,
        });
      } else {
        dispatch({
          type: GET_GAMES_FAILED,
          payload: "Uknown Error.",
        });
        dispatch({
          type: GET_GAMES_DIS_SPINNER,
        });
      }
    }
  };

export const getTopGames = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TOP_GAMES_EN_SPINNER,
    });
    const res = await axios.get(`${API_URL}/api/top_games`);
    if (res.data) {
      dispatch({
        type: GET_TOP_GAMES_SUCCESS,
        payload: res.data.data.rows[0],
      });
      dispatch({
        type: GET_TOP_GAMES_DIS_SPINNER,
      });
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.data) {
      dispatch({
        type: GET_TOP_GAMES_FAILED,
        payload: err.response.data,
      });
      dispatch({
        type: GET_TOP_GAMES_DIS_SPINNER,
      });
    } else {
      dispatch({
        type: GET_TOP_GAMES_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({
        type: GET_TOP_GAMES_DIS_SPINNER,
      });
    }
  }
};
export const getMiniGames = () => async (dispatch) => {
  try {
    // Dispatch action to show spinner
    dispatch({ type: GET_MINI_GAMES_EN_SPINNER });
    // Make API calls in parallel using Promise.all
    const res = await axios.get(`${API_URL}/api/f_games?size=100`);

    // Dispatch success action with payload
    dispatch({
      type: GET_MINI_GAMES_SUCCESS,
      payload: res.data.data,
    });
    // Dispatch action to hide spinner
    dispatch({ type: GET_MINI_GAMES_DIS_SPINNER });
  } catch (err) {
    // Log the error
    console.log(err);

    // Dispatch error action with payload
    dispatch({
      type: GET_MINI_GAMES_FAILED,
      payload: err?.response?.data || "Unknown Error.",
    });

    // Dispatch action to hide spinner
    dispatch({ type: GET_MINI_GAMES_DIS_SPINNER });
  }
};
export const openPlaylogiq = (playUrl) => async (dispatch) => {
  const token = localStorage.getItem("token");
  console.log(playUrl);
  try {
    dispatch({ type: OPEN_LOADING_ENABLE });
    var res = await axios.post(
      `https://api.elissabet.tn/api/v2/open?playUrl=${playUrl}${token}`,
      {
        type: 2,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data) {
      {
        dispatch({
          type: OPEN_SUCCESS,
          payload: res.data.message,
        });
      }
    }
  } catch (err) {
    if (err?.response?.data) {
      dispatch({
        type: OPEN_FAILED,
        payload: err.response.data.message,
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    } else {
      dispatch({
        type: OPEN_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    }
  }
};

export const openFiable = (playUrl) => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch({ type: OPEN_LOADING_ENABLE });
    var res = await axios.post(
      `${API_URL}/api/open_f_game`,
      {
        type: playUrl == "https://chicken.dinocrash.tn" ? 4 : 5,
        game_url: playUrl,
        device: window.innerWidth <= 768 ? "mobile" : "desktop",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data) {
      {
        dispatch({
          type: OPEN_SUCCESS,
          payload: res.data.data,
        });
      }
    }
  } catch (err) {
    if (err?.response?.data) {
      dispatch({
        type: OPEN_FAILED,
        payload: err.response.data.message,
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    } else {
      dispatch({
        type: OPEN_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    }
  }
};
export const openGapi = (game_name) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const userid = JSON.parse(localStorage.getItem("user")).id;
  const full_id = JSON.parse(localStorage.getItem("user")).full_id;

  try {
    dispatch({ type: OPEN_LOADING_ENABLE });
    var res = await axios.post(
      `${API_URL}/api/open`,
      {
        type: 1,
        game: game_name,
        login: userid,
        useragent: full_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data) {
      {
        dispatch({
          type: OPEN_SUCCESS,
          payload: res.data.data,
        });
      }
    }
  } catch (err) {
    if (err?.response?.data) {
      dispatch({
        type: OPEN_FAILED,
        payload: err.response.data.message,
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    } else {
      dispatch({
        type: OPEN_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    }
  }
};
export const openSoftswiss = (identifier) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const userid = JSON.parse(localStorage.getItem("user")).id;

  try {
    dispatch({ type: OPEN_LOADING_ENABLE });
    var res = await axios.post(
      `${API_URL}/api/open`,
      {
        client_type: window.innerWidth <= 768 ? "mobile" : "desktop",
        game: identifier,
        type: 2,
        user_id: userid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data) {
      {
        dispatch({
          type: OPEN_SUCCESS,
          payload: res.data.data,
        });
      }
    }
  } catch (err) {
    if (err?.response?.data) {
      dispatch({
        type: OPEN_FAILED,
        payload: err.response.data.message,
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    } else {
      dispatch({
        type: OPEN_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    }
  }
};

export const openZippelin = (playUrl) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: OPEN_LOADING_ENABLE });
    var res = await axios.post(
      `https://betsolutions.robet365.com/api/open`,
      {
        token: token,
        client_type: window.innerWidth <= 768 ? "mobile" : "desktop",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data) {
      {
        dispatch({
          type: OPEN_SUCCESS,
          payload: res.data.data,
        });
      }
    }
  } catch (err) {
    if (err?.response?.data) {
      dispatch({
        type: OPEN_FAILED,
        payload: err.response.data.message,
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    } else {
      dispatch({
        type: OPEN_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    }
  }
};

export const openCasino_8 = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const userid = JSON.parse(localStorage.getItem("user")).username;
  const usedUrl =
    data.producer == "pragmatic play" ? PRAGMATIC_URL :   data.producer == "pgsoft" ? PGSOFT_URL : AMATIC_URL;
  try {
    var res = await axios.post(
      `${usedUrl}/userAuth`,
      {
        gameid: data.identifier,
        provider: data.producer,
        userID: userid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data) {
      {
        dispatch({
          type: OPEN_SUCCESS,
          payload: res.data.message,
        });
      }
    }
  } catch (error) {
    if (error?.response?.data) {
      dispatch({
        type: OPEN_FAILED,
        payload: error.response.data.message,
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    } else {
      dispatch({
        type: OPEN_FAILED,
        payload: "Uknown Error.",
      });
      dispatch({ type: OPEN_LOADING_DISABLE });
    }
  }
};

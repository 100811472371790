import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Casino from "./screens/Casino";
import LiveCasino from "./screens/LiveCasino";
import Sport from "./screens/SportNew";
import SportOld from "./screens/Sport";
import TermsAndContions from "./screens/TermsAndConditions";
import CasinoGameModal from "./screens/CasinoModal";
import MiniGames from "./screens/MiniGames";
import { useSocket } from "./context/SocketContext";
import Account from "./screens/Account";
import LiveSport from "./screens/LiveSport";
import VirtualGames from "./screens/Virtual";
import { ProtectedRoute } from "./protected_routes";
import AuthVerify from "./utils/checkJWT";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [userData, setuserData] = useState(null);
  const { socket, connectSocket } = useSocket();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!socket) {
      connectSocket();
    }
  }, []);
  const logOut = useCallback(() => {
    localStorage.clear();
  }, [dispatch]);

  useEffect(() => {
    const storedUserData = localStorage.getItem("user");
    setuserData(storedUserData);
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Sport />} />
        <Route path="/betting" element={<Sport />} />
        <Route path="/betting/live" element={<LiveSport />} />
        {/* <Route path="/betting/virtual" element={<VirtualSport />} /> */}
        <Route path="/casino" element={<Casino />} />
        <Route path="/live-casino" element={<LiveCasino />} />
        <Route path="/mini-games" element={<MiniGames />} />
        <Route path="/virtual" element={<VirtualGames />} />
        <Route path="/terms-conditions" element={<TermsAndContions />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/my-account" element={<Account />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/open" element={<CasinoGameModal />} />
        </Route>
        <Route path="*" element={<Casino />} />
      </Routes>
      <AuthVerify logOut={logOut} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthVerify = (props) => {
    const navigate = useNavigate();

    let location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedJwt = jwtDecode(token);
            if (decodedJwt.exp < Date.now() / 1000) {
                props.logOut();
                navigate("/betting", { replace: true });
                toast.warning('Logout due to innactive session', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }

    }, [location, props]);

    return;
};

export default AuthVerify;

import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_LOADING_ENABLE,
  LOGIN_LOADING_DISABLE,
  RESET_AUTH_ERROR,
} from "../types";

const initialState = {
  user: null,
  token: null,
  loading: false,
  isError: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_AUTH_ERROR:
      return {
        ...state,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: localStorage.setItem(
          "user",
          JSON.stringify({
            username: action.payload.username,
            full_id: action.payload.full_id,
            id: action.payload.id,
            full_id_user: action.payload.full_id_user
          })
        ),
        token: localStorage.setItem("token", action.payload.accessToken),
        isError: false,
        error: null,
      };
    case LOGIN_LOADING_ENABLE:
      return { ...state, loading: true };
    case LOGIN_LOADING_DISABLE:
      return { ...state, loading: false };
    case LOGIN_FAILED:
      return {
        ...state,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;

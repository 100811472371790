import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { changePasswordUser } from '../../redux/actions/user';

const ChangePassword = ({activeTab}) => {
  const dispatch = useDispatch()
  const {
    change_pass, 
    change_pass_err,
    change_pass_loading
  } = useSelector((state) => state.user);

  const token = localStorage.getItem("token") ?? null;
  const [old_password, setold_password] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [confirmNewPassword, setconfirmNewPassword] = useState('');
  const [localError, setlocalError] = useState(null);
  const [localSuccess, setlocalSuccess] = useState(null);

  const handleChangeOldPassword = (e) => {
    setold_password(e.target.value);
  }

  const handleNewPassword = (e) => {
    setnewPassword(e.target.value)
  }

  const handleConfirmNewPassword = (e) => {
    setconfirmNewPassword(e.target.value)
  }

  const handleChangePassword = () => {
    if (newPassword === confirmNewPassword) {
    let body = {
        old_password: old_password,
        new_password: newPassword
    }
    dispatch(changePasswordUser(body,token))
    }else {
        setlocalError("new password and confirm password not equals")
        setlocalSuccess(null)

    }
  }

  useEffect(() => {
    if (change_pass_err) {
        setlocalError(change_pass_err)
        setlocalSuccess(null)
    }
    if (change_pass) {
        setlocalSuccess(change_pass)
        setlocalError(null)
    }
  }, [change_pass_err , change_pass])
  

  return (
    <div className="col-xl-9 col-lg-9 col-12" style={{ display: activeTab === 'change_password' ? 'block' : 'none' }}>
        <div className="content-main touch-left">
            <h3 className="page-title">Change Password</h3>

            <form action="/" className="form form-change-password">
                <div className="row">
                    { localError &&
                        <div role="alert" class="alert alert-danger mt-3" style={{ width:'100%' }}>
                        <h5 class="alert-heading">Error:</h5> 
                            <p class="mb-0">
                                {localError}
                            </p>
                        </div>
                    }
                        { localSuccess &&
                        <div role="alert" class="alert alert-success mt-3" style={{ width:'100%' }}>
                        <h5 class="alert-heading"></h5> 
                            <p class="mb-0">
                                {localSuccess}
                            </p>
                        </div>
                    }
                    <div className="col-lg-6 col-12">
                        <div className="form-group">
                            <label for="user-old-password" className="form-control-label">
                                Old Password									
                            </label> 
                            <div className="input-group">
                                <span className="input-group-addon"> <i className="fa fa-lock"></i></span> 
                                <input 
                                    onChange={handleChangeOldPassword}
                                    value={old_password}
                                    type="password" 
                                    id="user-old-password" 
                                    required="required" 
                                    className="form-control" 
                                />
                            </div>
                        </div> 
                    <div className="form-group">
                        <label for="user-new-password" className="form-control-label">
                            New Password
                        </label> 
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fa fa-lock" /></span> 
                            <input 
                                onChange={handleNewPassword}
                                value={newPassword}
                                type="password" 
                                id="user-new-password" 
                                required="required" 
                                className="form-control" 
                            />
                        </div>
                    </div> 
                    <div className="form-group">
                        <label for="user-confirm-password" className="form-control-label">
                            Repeat Password									
                        </label> 
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fa fa-lock" /></span> 
                            <input 
                                onChange={handleConfirmNewPassword}
                                value={confirmNewPassword}
                                type="password" 
                                id="user-confirm-password" 
                                required="required" 
                                className="form-control" 
                            />
                        </div>
                    </div>
                </div> 
                <div className="col-12">
                    <hr /> 
                    <div 
                        style={{
                            cursor:'pointer'
                        }}
                        onClick={handleChangePassword}
                        title="Change Password" 
                        className={`btn btn-lg btn-primary ${change_pass_loading && 'btn-loading'} mt-3`}
                    >
                        Change Password								
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

  )
}

export default ChangePassword
import {
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILED,
    GET_USER_DETAILS_SPINNER_EN,
    GET_USER_DETAILS_SPINNER_DIS,
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_SPINNER_EN,
    CHANGE_PASSWORD_SPINNER_DIS,
    TRANSFER_HISTORY_SUCCESS,
    TRANSFER_HISTORY_FAILED,
    TRANSFER_HISTORY_SPINNER_EN,
    TRANSFER_HISTORY_SPINNER_DIS,
    CONVERT_SUCCESS,
    CONVERT_LOADING_ENABLE,
    CONVERT_LOADING_DISABLE,
    CONVERT_FAILED,
    CHANGE_PASSWORD_SUCCESS,
    CASINO_HISTORY_SUCCESS,
    CASINO_HISTORY_FAILED,
    CASINO_HISTORY_SPINNER_EN,
    CASINO_HISTORY_SPINNER_DIS,
} from '../types'

const initialState = {
    user: null,
    loading: false,
    error: null,
    change_pass: null,
    change_pass_err: null,
    change_pass_loading: false,
    transfer_loading: false,
    transfer_error: null,
    transfers: [] , 
    casino : [],
    casino_loading: false,
    casino_error: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                change_pass_loading: false,
                change_pass: action.payload
            }
        case CHANGE_PASSWORD_SPINNER_EN:
            return {
                ...state,
                change_pass_loading: true
            }
        case CHANGE_PASSWORD_SPINNER_DIS:
            return {
                ...state,
                change_pass_loading: false
            }
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                change_pass_loading: action.payload
            }
        case GET_USER_DETAILS_SPINNER_EN:
            return {
                ...state,
                loading: true
            }
        case GET_USER_DETAILS_SPINNER_DIS:
            return {
                ...state,
                loading: false
            }
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                user: action.payload.data
            }
        case GET_USER_DETAILS_FAILED:
            return {
                ...state,
                error: action.payload
            }

        case TRANSFER_HISTORY_SUCCESS:
            return {
                ...state,
                transfers: action.payload.data
            }
        case TRANSFER_HISTORY_FAILED:
            return {
                ...state,
                transfer_error: action.payload
            }
        case TRANSFER_HISTORY_SPINNER_EN:
            return {
                ...state,
                transfer_loading: true
            }
        case TRANSFER_HISTORY_SPINNER_DIS:
            return {
                ...state,
                transfer_loading: false
            }

        case CONVERT_SUCCESS:
            return {
                ...state,
                success: action.payload,
                isError: false,
                error: null,
            };
        case CONVERT_LOADING_ENABLE:
            return { ...state, loading: true };
        case CONVERT_LOADING_DISABLE:
            return { ...state, loading: false };
        case CONVERT_FAILED:
            return {
                ...state,
                isError: true,
                error: action.payload,
            };
            case CASINO_HISTORY_SUCCESS:
                return {
                    ...state,
                    casino: action.payload.data
                }
            case CASINO_HISTORY_FAILED:
                return {
                    ...state,
                    casino_error: action.payload
                }
            case CASINO_HISTORY_SPINNER_EN:
                return {
                    ...state,
                    casino_loading: true
                }
            case CASINO_HISTORY_SPINNER_DIS:
                return {
                    ...state,
                    casino_loading: false
                }
        default:
            return state;
    }
};

export default userReducer;
export const GET_HOME_GAMES_SUCCESS = "GET_HOME_GAMES_SUCCESS"
export const GET_HOME_GAMES_FAILED = "GET_HOME_GAMES_FAILED"
export const GET_HOME_GAMES_EN_SPINNER = "GET_HOME_GAMES_EN_SPINNER"
export const GET_HOME_GAMES_DIS_SPINNER = "GET_HOME_GAMES_DIS_SPINNER"

export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAILED = "GET_CATEGORY_FAILED"
export const GET_CATEGORY_EN_SPINNER = "GET_CATEGORY_EN_SPINNER"
export const GET_CATEGORY_DIS_SPINNER = "GET_CATEGORY_DIS_SPINNER"

export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS"
export const GET_PROVIDERS_FAILED = "GET_PROVIDERS_FAILED"
export const GET_PROVIDERS_EN_SPINNER = "GET_PROVIDERS_EN_SPINNER"
export const GET_PROVIDERS_DIS_SPINNER = "GET_PROVIDERS_DIS_SPINNER"

export const GET_GAMES_SUCCESS = "GET_GAMES_SUCCESS"
export const GET_GAMES_FAILED = "GET_GAMES_FAILED"
export const GET_GAMES_EN_SPINNER = "GET_GAMES_EN_SPINNER"
export const GET_GAMES_DIS_SPINNER = "GET_GAMES_DIS_SPINNER"

export const GET_MINI_GAMES_SUCCESS = "GET_MINI_GAMES_SUCCESS"
export const GET_MINI_GAMES_FAILED = "GET_MINI_GAMES_FAILED"
export const GET_MINI_GAMES_EN_SPINNER = "GET_MINI_GAMES_EN_SPINNER"
export const GET_MINI_GAMES_DIS_SPINNER = "GET_MINI_GAMES_DIS_SPINNER"


export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGIN_LOADING_ENABLE = "LOGIN_LOADING_ENABLE"
export const LOGIN_LOADING_DISABLE = "LOGIN_LOADING_DISABLE"

export const CONVERT_SUCCESS = "CONVERT_SUCCESS"
export const CONVERT_FAILED = "CONVERT_FAILED"
export const CONVERT_LOADING_ENABLE = "CONVERT_LOADING_ENABLE"
export const CONVERT_LOADING_DISABLE = "CONVERT_LOADING_DISABLE"

export const GET_TOP_GAMES_SUCCESS = "GET_TOP_GAMES_SUCCESS"
export const GET_TOP_GAMES_FAILED = "GET_TOP_GAMES_FAILED"
export const GET_TOP_GAMES_EN_SPINNER = "GET_TOP_GAMES_EN_SPINNER"
export const GET_TOP_GAMES_DIS_SPINNER = "GET_TOP_GAMES_DIS_SPINNER"

export const RESET_PROVIDER_STATE = "RESET_PROVIDER_STATE"
export const RESET_CATEGORIES_STATE = "RESET_CATEGORIES_STATE"

export const RESET_HOMEGAMES_STATE = "RESET_HOMEGAMES_STATE"
export const RESET_GAMES_STATE = "RESET_GAMES_STATE"
export const RESET_OPEN_STATE = "RESET_OPEN_STATE"

export const OPEN_SUCCESS = "OPEN_SUCCESS"
export const OPEN_FAILED = "OPEN_FAILED"
export const OPEN_LOADING_ENABLE = "OPEN_LOADING_ENABLE"
export const OPEN_LOADING_DISABLE = "OPEN_LOADING_DISABLE"

export const RESET_AUTH_ERROR = "RESET_AUTH_ERROR"



// get user detail
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED";
export const GET_USER_DETAILS_SPINNER_EN = "GET_USER_DETAILS_SPINNER_EN";
export const GET_USER_DETAILS_SPINNER_DIS = "GET_USER_DETAILS_SPINNER_DIS";

// change password
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const CHANGE_PASSWORD_SPINNER_EN = "CHANGE_PASSWORD_SPINNER_EN";
export const CHANGE_PASSWORD_SPINNER_DIS = "CHANGE_PASSWORD_SPINNER_DIS";

// transfer history.
export const TRANSFER_HISTORY_SUCCESS = "TRANSFER_HISTORY_SUCCESS"
export const TRANSFER_HISTORY_FAILED = "TRANSFER_HISTORY_FAILED"
export const TRANSFER_HISTORY_SPINNER_EN = "TRANSFER_HISTORY_SPINNER_EN"
export const TRANSFER_HISTORY_SPINNER_DIS = "TRANSFER_HISTORY_SPINNER_DIS"

// transfer history.
export const CASINO_HISTORY_SUCCESS = "CASINO_HISTORY_SUCCESS"
export const CASINO_HISTORY_FAILED = "CASINO_HISTORY_FAILED"
export const CASINO_HISTORY_SPINNER_EN = "CASINO_HISTORY_SPINNER_EN"
export const CASINO_HISTORY_SPINNER_DIS = "CASINO_HISTORY_SPINNER_DIS"
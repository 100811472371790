import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
export const ProtectedRoute = () => {
  const token = localStorage.getItem("token")
  const location = useLocation()
    return (<>
      {!(token) ? (
        <Navigate to="/" state={{ from: location }} replace />
      ) : (
        <Outlet/>
      )}
  </>);
};

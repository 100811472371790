import React, { useState, Fragment, useEffect, useCallback } from "react";
import Caroussel from "../componenets/shared/Caroussel";
import NavBar from "../componenets/shared/NavBar";
import MobileAuthSidebar from "../componenets/shared/MobileAuthSidebar";
import MobileSidebar from "../componenets/shared/MobileSidebar";
import { Helmet } from "react-helmet";
import { useSocket } from "../context/SocketContext";
import { useLocation } from "react-router-dom";

const Sport = () => {
  const [isAuthMobileSideBar, setisAuthMobileSideBar] = useState(false);
  const [isSidebarOpened, setisSidebarOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sportBlocked, setsportBlocked] = useState(false);
  const { socket } = useSocket();
  const location = useLocation();

  useEffect(() => {
    handleCloseMobileSideBar(); // Close the sidebar when the location changes
  }, []);

  const checkIfSportBlocked = (data) => {
    if (data.sport) {
      setsportBlocked(true);
    }
  };

  // check if casino maintenance.
  useEffect(() => {
    if (socket) {
      socket.on("status", checkIfSportBlocked);
    }
    return () => {
      if (socket) {
        socket.off("status", checkIfSportBlocked);
      }
    };
  }, [socket]);

  const handleOpenAuthSidebar = () => {
    setisSidebarOpened(false);
    setisAuthMobileSideBar(true);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.add(className);
    });
  };

  const handleCloseAuthSidebar = () => {
    setisAuthMobileSideBar(false);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.remove(className);
    });
  };
  const handleOpenMobileSideBar = () => {
    setisAuthMobileSideBar(false);
    // this function will change the style of html balise style and add like the current site we clone.
    setisSidebarOpened(true);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.add(className);
    });
    // htmlElement.classList.remove('mm-right');
    // classNames.classList.toggle('mm-right',isSidebarOpened)
  };

  const handleCloseMobileSideBar = () => {
    // this function will change the style of html balise style and add like the current site we clone.
    setisSidebarOpened(false);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.remove(className);
    });
    // htmlElement.classList.remove('mm-right');
    // classNames.classList.toggle('mm-right',isSidebarOpened)
  };
  useEffect(() => {
  }, [isSidebarOpened]);

  const handleToggleSidebar = () => {
    if (isSidebarOpened) {
      handleCloseMobileSideBar();
    } else {
      handleOpenMobileSideBar();
    }
  };

  useEffect(() => {
    // const options = {
    //   container: "#altenarsportsbook",
    //   lang: "en-GB",
    //   isfixed: false,
    //   fixedtop: 0,
    //   fixedbottom: 0,
    //   numformat: "en",
    //   //oddstype: "decimal",
    //   walletcode: "280222" ,
    //   skinid: "dazzabet",
    //   token: localStorage.getItem("token"),
    // };
    // // initializeAltenarSportsbook();

    // // if (window.AltenarSportsbook) {
    //   const widgetInstance = window.altenarWSDK.addWidget()

    //   // var ASb = new window.AltenarSportsbook("#altenarsportsbook", initParams);
    // // }
    // return () => {
    //   widgetInstance.destroy();
    // };
    // To change culture and oddsFormat
    // window.altenarWSDK.set({
    //   culture: "'en-GB'",
    //   oddsFormat: 2,
    // });

    // // To clear bet slip and add odds
    // window.altenarWSDK.set({
    //   oddIds: [222, 333],
    //   clearBetSlip: true,
    // });

    if (window.altenarWSDK) {
      // initialize altenarWSDK
      window.altenarWSDK.init({
        integration: "dazzabet",
        culture: "en-GB",
        token: localStorage.getItem("token"),
      });

      // add sports book
      var ASBNEW = window.altenarWSDK.addSportsBook({
        props: {
          page: "overview",
        },
        tokens: {
          TopLeagueBox: {
            background: "#1d1d1d",
          },
          // details sports list
          TopSport: {
            iconBackground: "#008044",
            iconColor: "#008044",
            color: "#008044",
          },
          // mybets bar icons
          TopSportBarButton: {
            iconBackground: "#008044",
            iconColor: "#008044",
            color: "#fff",
          },
          // odds button
          OddBox: {
            spacing: 4,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: "#d5d5d5",
            background: "#DCDCDC",
            paddingVertical: 8,
            paddingHorizontal: 16,
            oddColor: "rgba(0, 0, 0, 0.87)",
            labelColor: "rgba(0, 0, 0, 0.54)",
            iconColor: "rgba(0, 0, 0, 0.87)",
            iconHeight: 22,
            iconWidth: 22,
          },
          EventBox: {
            background: "#2d2d2d",
            scoreColor: "#008044",
          },
          EventBoxDetailsButton: {
            borderColor: "#d5d5d5",
            background: "#DCDCDC",
            iconColor: "#008044",
          },
        },
        container: document.getElementById("altenar-container"),
      });
    }
    return () => {
      ASBNEW.remove();
    };
  }, []);

  return (
    <Fragment>
      <MobileAuthSidebar
        isOther={isSidebarOpened}
        isAuthMobileSideBar={isAuthMobileSideBar}
        handleClose={handleCloseAuthSidebar}
      />
      <MobileSidebar
        isOther={isAuthMobileSideBar}
        isOpen={isSidebarOpened}
        handleClose={handleCloseMobileSideBar}
      />

      <div className="mm-page mm-slideout">
        <NavBar
          isAuthMobileSideBar={isAuthMobileSideBar}
          isSidebarOpened={isSidebarOpened}
          handleOpenAuth={handleOpenAuthSidebar}
          handleCloseAuth={handleCloseAuthSidebar}
          handleOpenSidebar={handleOpenMobileSideBar}
          handleCloseSidebar={handleCloseAuthSidebar}
          handleToggleSidebar={handleToggleSidebar}
          activeNav={"sport"}
        />
        <Caroussel />
        {sportBlocked ? (
          <h3 style={{ color: "white" }}>
            Sport is currently in maintenance mode.
          </h3>
        ) : (
          <div id="altenar-container" />
        )}
      </div>
    </Fragment>
  );
};
export default Sport;

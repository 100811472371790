import React, { useState, useEffect } from "react";
// import { useAuthState, useAuthDispatch, doLogin } from "../../context/AuthContext";
import { ClientJS } from "clientjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authentification } from "../../redux/actions/auth";
import { useSocket } from "../../context/SocketContext";
import { RESET_AUTH_ERROR } from "../../redux/types";
import { FaEye, FaEyeSlash, FaLock, FaUser } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectLang from "../SelectLang/SelectLang";
import bibeetLogo from "../../assets/images/new_logo_green.png";
import { convertCashbackApi } from "../../redux/actions/user";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const NavBar = ({
  activeNav,
  handleOpenAuth,
  handleOpenSidebar,
  handleCloseAuth,
  handleCloseSidebar,
  isAuthMobileSideBar,
  handleToggleSidebar,
  isSidebarOpened,
}) => {
  const [userDropdown, setuserDropdown] = useState(false);
  const [cashDropdown, setcashDropdown] = useState(false);
  const [selectLangOpen, setselectLangOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("lang") || "en";
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      setSelectedLanguage("en");
      i18n.changeLanguage("en");
    }
  }, []);

  const toogleSelectLang = () => {
    setselectLangOpen(!selectLangOpen);
  };

  const toggleUserDropdown = () => {
    setuserDropdown(!userDropdown);
    setcashDropdown(false);
  };

  const toggleCashDropdown = () => {
    setcashDropdown(!cashDropdown);
    setuserDropdown(false);
  };

  const { socket, disconnectSocket } = useSocket();
  const [forceDisconnect, setforceDisconnect] = useState(false);
  const [bloque, setbloque] = useState(false);
  const [cashback, setcashback] = useState(null);
  const [balance, setbalance] = useState(null);
  const [convertCashback, setConvertCashback] = useState(false);

  const { error, loading, user } = useSelector((state) => state.auth);

  // token and userData:
  const token =
    localStorage.getItem("token") !== undefined &&
    localStorage.getItem("token") !== null
      ? localStorage.getItem("token")
      : null;
  const userData =
    localStorage.getItem("user") !== undefined &&
    localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null;

  // this function apply changes of socket.
  const handleBalanceListning = (data) => {
    if (data.force_disconnect) {
      socket.disconnect();
      localStorage.clear();
      navigate("/betting", { replace: true });
    }
    setforceDisconnect(data.force_disconnect);
    setbalance(data.balance);
    setcashback(data.cashback);
    setbloque(data.blocked);
  };

  useEffect(() => {
    if (socket && userData) {
      const fpPromise = FingerprintJS.load();
      fpPromise
        .then((fp) => fp.get())
        .then((result) => {
          socket.emit("Getbalance", {
            id: userData.id,
            username: userData.username,
            fingerprint: result.visitorId,
          });
        });
      socket.on("balance", handleBalanceListning);
      // socket.on("status",checkIfSiteMaintenance);
    }
    return () => {
      dispatch({
        type: RESET_AUTH_ERROR,
      });
      if (socket) {
        socket.off("balance", handleBalanceListning);
        // socket.off("status",checkIfSiteMaintenance)
      }
    };
  }, [socket]);

  useEffect(() => {
    if (cashback > 0) {
      dispatch(convertCashbackApi());
    }
    return () => {};
  }, [convertCashback]);

  // this is for a bad example for socket emit to getBalance each time.

  const [passwordplain, setpasswordplain] = useState(false);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [navbarTab, setNavBarTab] = useState(activeNav);

  const togglePasswordText = () => {
    setpasswordplain(!passwordplain);
  };

  const dispatch = useDispatch();
  const ip = "197.27.118.167";

  return (
    <>
      {/* <MobileAuthNav /> */}
      <header className="header mm-opened mm-blocking mm-background mm-right mm-next mm-effect-listitems-slide mm-shadow-page mm-opening">
        <div className="head-master">
          <div className="container">
            <div className="row align-items-center hidden-lg-up no-print-data">
              <div className="col-lg-4 col-12 logo-wrap">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleToggleSidebar}
                  href="#nav-mobile"
                  className="btn-mobile-trigger btn btn-white-outline btn-sm hidden-lg-up"
                >
                  <HiMenu
                    style={{
                      marginTop: "2px",
                    }}
                    className="regacc-icon"
                    size={20}
                  />
                </div>
                <site-logo className="site-logo" >
                  <div href="" onClick={() => navigate('/betting')}>
                    <img
                      className="bibeet-mobile"
                      src={bibeetLogo}
                      alt="bibeet"
                    />
                  </div>
                </site-logo>
                {/* to fix navbar mobile. */}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={
                    isAuthMobileSideBar ? handleCloseAuth : handleOpenAuth
                  }
                  className="btn-user-trigger btn btn-white-outline btn-sm hidden-lg-up"
                >
                  <FaUser className="regacc-icon mr-1" size={10} />
                  {token ? userData["username"] : "Login / Register"}
                </div>
              </div>
            </div>

            {/* <MobileNavbar ToggleSidebar={ToggleSidebar} isOpen={isOpen}/> */}
            <div className="row row-header-desktop justify-content-md-center hidden-md-down">
              <div className="col-md-auto d-flex logo">
                <div href="" className="desktop-logo"  onClick={() => navigate('/betting')}>
                  <img
                    className="bibeet-mobile"
                    src={bibeetLogo}
                    alt="bibeet"
                  />
                </div>
              </div>

              <div className="col">
                <div className="header-desktop-menu">
                  <ul className="nav navigation-main align-items-center">
                    {/* {localStorage.getItem("user") &&
                      +JSON.parse(localStorage.getItem("user")).full_id_user <
                        47887 && (
                        <li className="nav-item">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setNavBarTab("sportold");
                              handleCloseSidebar();
                              navigate("/old");
                            }}
                            title="old Sports Betting"
                            className={`${
                              navbarTab === "sportold" && "active"
                            } nav-link`}
                          >
                            <span className="betting-btn">
                              {t("old sport")}
                            </span>
                          </div>
                        </li>
                      )} */}
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNavBarTab("sport");
                          handleCloseSidebar();
                          navigate("/betting");
                        }}
                        title="Sports Betting"
                        className={`${
                          navbarTab === "sport" && "active"
                        } nav-link`}
                      >
                        <span className="betting-btn">
                          {t("Sport Betting")}
                        </span>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNavBarTab("sport-live");
                          handleCloseSidebar();
                          navigate("/betting/live");
                        }}
                        title="Live Betting"
                        className={`${
                          navbarTab === "sport-live" && "active"
                        } nav-link`}
                      >
                        <span className="live-btn">{t("Live Betting")}</span>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNavBarTab("casino");
                          handleCloseSidebar();
                          navigate("/casino");
                        }}
                        title="Casino"
                        className={`${
                          navbarTab === "casino" && "active"
                        } nav-link`}
                      >
                        <span className="casino-btn">{t("Casino")}</span>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNavBarTab("live-casino");
                          handleCloseSidebar();
                          navigate("/live-casino");
                        }}
                        title="Live Casino"
                        className={`${
                          navbarTab === "live-casino" && "active"
                        } nav-link`}
                      >
                        <span className="live-casino-btn">
                          {t("Live Casino")}{" "}
                        </span>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNavBarTab("mini-games");
                          handleCloseSidebar();
                          navigate("/mini-games");
                        }}
                        title="Mini Games"
                        className={`${
                          navbarTab === "mini-games" && "active"
                        } nav-link`}
                      >
                        <span className="live-games-btn">
                          {t("Mini Games")}
                        </span>
                      </div>
                    </li>
                    {/* <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNavBarTab("VirtualSport");
                          navigate("/betting/virtual");
                        }}
                        title="VirtualSport"
                        className={`${navbarTab === "VirtualSport" && "active"
                          } nav-link`}
                      >
                        <span className="virtual-btn">{t("Virtuals")}</span>
                      </div>
                    </li> */}
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNavBarTab("VirtualCasino");
                          handleCloseSidebar();
                          navigate("/virtual");
                        }}
                        title="VirtualCasino"
                        className={`${
                          navbarTab === "VirtualCasino" && "active"
                        } nav-link`}
                      >
                        <span className="virtual-btn">
                          {t("Virtual Games")}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {!token ? (
                <div className="col-md-auto d-flex justify-content-end hidden-md-down">
                  <form className="form-inline form-login flex-lg-nowrap mb-2">
                    <div className="input-group on-dark mr-lg-2 mb-lg-0 mb-2">
                      <span className="input-group-addon">
                        <FaUser
                          className="icon-re"
                          size={15}
                          color={"#454444"}
                        />
                      </span>
                      <span style={{ color: "white" }}>{token}</span>
                      <input
                        onChange={(e) => {
                          setusername(e.target.value);
                          dispatch({
                            type: RESET_AUTH_ERROR,
                          });
                        }}
                        type="text"
                        value={username}
                        className="form-control login-username"
                        placeholder="Username"
                        required=""
                      />
                    </div>

                    <div className="input-group on-dark mr-lg-2 mb-lg-0 mb-2">
                      <span className="input-group-addon">
                        <FaLock
                          className="icon-re"
                          size={15}
                          color={"#454444"}
                        />
                      </span>
                      <input
                        type={!passwordplain ? "password" : "text"}
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                          dispatch({
                            type: RESET_AUTH_ERROR,
                          });
                        }}
                        className="form-control login-password"
                        placeholder="Password"
                        required=""
                      />
                      {/* <div onClick={() => navigate('/forgot-password')} title="Forgot password?" className="forgot-password-desktop">
                        Forgot password?		</div> */}
                      <span
                        onClick={togglePasswordText}
                        className="input-group-addon"
                      >
                        {!passwordplain ? (
                          <FaEye
                            className="icon-re"
                            size={15}
                            color={"#454444"}
                          />
                        ) : (
                          <FaEyeSlash
                            className="icon-re"
                            size={15}
                            color={"#454444"}
                          />
                        )}
                      </span>
                    </div>

                    <button
                      type="submit"
                      className={`btn btn-primary mr-sm-2 mb-sm-0 btn-login-form ${
                        loading && "btn-loading"
                      }`}
                      data-original-title=""
                      title=""
                      onClick={(e) => {
                        // to disable refreshing page;
                        e.preventDefault();
                        let body = {
                          username: username,
                          password: password,
                        };
                        dispatch(authentification(body, navigate));
                      }}
                    >
                      Login
                    </button>
                    {error && (
                      <div className="tooltip-login">
                        <span className="tooltiptext">Wrong Credentials</span>
                      </div>
                    )}
                  </form>
                </div>
              ) : (
                <div
                  style={{
                    marginRight: "8rem",
                    padding: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                >
                  {" "}
                  <div className="col-md-auto d-flex justify-content-end hidden-md-down">
                    <div className={`btn-group user-hud`}>
                      <div
                        className={`btn-group user-hud-balance ${
                          cashDropdown ? "show" : ""
                        }`}
                      >
                        <button
                          onClick={toggleCashDropdown}
                          id="user-hud-balance-btn"
                          type="button"
                          className="btn btn-white-outline dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Cash:
                          <span className="js-balance-cash">
                            TND{" "}
                            {balance ? (
                              balance
                            ) : (
                              <span className="balance-spinner"></span>
                            )}
                          </span>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="user-hud-balance-btn"
                        >
                          <li className="dropdown-item">
                            Cashback:{" "}
                            <span className="js-sports-bonus">
                              TND{" "}
                              {cashback ? (
                                cashback
                              ) : (
                                <span className="balance-spinner"></span>
                              )}
                            </span>
                          </li>
                          <li className="dropdown-item dropdown-balance-refresh">
                            {loading ? (
                              <div className="game-spinner"></div>
                            ) : (
                              <button
                                onClick={() => setConvertCashback(true)}
                                className="btn btn-lg btn-primary btn-refresh"
                              >
                                Convert
                              </button>
                            )}
                          </li>
                        </div>
                      </div>

                      <div
                        className={`btn-group ${userDropdown ? "show" : ""}`}
                      >
                        <button
                          onClick={toggleUserDropdown}
                          id="user-account-menu"
                          type="button"
                          className="btn btn-white-outline dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <FaUser
                            className="icon-re"
                            style={{ top: 0 }}
                            size={11}
                            color={!userDropdown ? "#dc9125" : "rgb(29 31 30)"}
                          />
                          <span className="js-username">
                            {userData && userData.username}
                          </span>
                        </button>

                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="user-account-menu"
                        >
                          <Link
                            to="/my-account"
                            state={token ? { activeTab: "account" } : {}}
                            className="dropdown-item "
                            title="My account"
                          >
                            My account
                          </Link>

                          <Link
                            to="/my-account"
                            state={token ? { activeTab: "casino_history" } : {}}
                            className="dropdown-item"
                          >
                            Casino History
                          </Link>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              disconnectSocket();
                              localStorage.clear();
                              navigate("/betting", { replace: true });
                            }}
                            className="dropdown-item btn-logout"
                            title="Logout"
                          >
                            Logout{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              )}

              <div className="navigation-right">
                <ul className="language-list">
                  <div
                    onClick={toogleSelectLang}
                    className="language-list-item"
                    id="lang-trigger"
                    title="English"
                    data-lang="en"
                  >
                    <span
                      className="flag flag-gb rounded-circle"
                      alt="English"
                    ></span>
                    <i
                      className="language-arrow fa fa-chevron-down"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <SelectLang
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    selectLangOpen={selectLangOpen}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default NavBar;

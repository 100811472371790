import React, { useState, useEffect, Fragment } from "react";
import CategoryNavbar from "../componenets/casino/CategoryNavbar";
import GamesList from "../componenets/casino/GameList";
import Footer from "../componenets/shared/Footer";
import MobileAuthSidebar from "../componenets/shared/MobileAuthSidebar";
import MobileSidebar from "../componenets/shared/MobileSidebar";
import NavBar from "../componenets/shared/NavBar";
import LoginModal from "../componenets/loginModal/LoginModal";
import { useSocket } from "../context/SocketContext";
import { useLocation } from "react-router-dom";

const Casino = () => {
  const location = useLocation();
  const [openLogin, setOpenLogin] = useState(false);
  const [isAuthMobileSideBar, setisAuthMobileSideBar] = useState(false);
  const [isSidebarOpened, setisSidebarOpened] = useState(false);
  const fromProvider = location.state?.fromProvider;
  const [tab, setTab] = useState("");
  const [provider, setProvider] = useState("");

  const [casinoBlocked, setcasinoBlocked] = useState(false);
  const { socket } = useSocket();

  useEffect(() => {
    if (fromProvider && fromProvider !== "") {
      setProvider(fromProvider);
    }
  }, [fromProvider]);

  const checkIfCasinoMaintenance = (data) => {
    if (data.casino) {
      setcasinoBlocked(true);
    }
  };

  // check if casino maintenance.
  useEffect(() => {
    handleCloseMobileSideBar(); // Close the sidebar when the location changes
  }, [location]);
  // check if casino maintenance.
  useEffect(() => {
    if (socket) {
      socket.on("status", checkIfCasinoMaintenance);
    }
    return () => {
      if (socket) {
        socket.off("status", checkIfCasinoMaintenance);
      }
    };
  }, [socket]);

  const handleOpenAuthSidebar = () => {
    setisSidebarOpened(false);
    setisAuthMobileSideBar(true);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.add(className);
    });
  };

  const handleCloseAuthSidebar = () => {
    setisAuthMobileSideBar(false);
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-right",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.remove(className);
    });
  };

  const handleOpenMobileSideBar = () => {
    // this function will change the style of html balise style and add like the current site we clone.
    setisAuthMobileSideBar(false);
    setisSidebarOpened(true);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.add(className);
    });
    // htmlElement.classList.remove('mm-right');
    // classNames.classList.toggle('mm-right',isSidebarOpened)
  };

  const handleCloseMobileSideBar = () => {
    // this function will change the style of html balise style and add like the current site we clone.
    setisSidebarOpened(false);
    //mm-opened mm-blocking mm-background mm-next mm-effect-listitems-slide mm-shadow-page mm-opening
    const htmlElement = document.querySelector("html");
    const classNames = [
      "mm-opened",
      "mm-blocking",
      "mm-background",
      "mm-next",
      "mm-effect-listitems-slide",
      "mm-shadow-page",
      "mm-opening",
    ];
    classNames.forEach((className) => {
      htmlElement.classList.remove(className);
    });
  };

  const handleToggleSidebar = () => {
    if (isSidebarOpened) {
      handleCloseMobileSideBar();
    } else {
      handleOpenMobileSideBar();
    }
  };

  return (
    <Fragment>
      <LoginModal openLogin={openLogin} setOpenLogin={setOpenLogin} />
      <MobileAuthSidebar
        isOther={isSidebarOpened}
        isAuthMobileSideBar={isAuthMobileSideBar}
        handleClose={handleCloseAuthSidebar}
      />
      <MobileSidebar
        isOther={isAuthMobileSideBar}
        isOpen={isSidebarOpened}
        handleClose={handleCloseMobileSideBar}
      />

      <div
        id="root"
        className="mm-page mm-slideout"
        style={{ minHeight: isAuthMobileSideBar && "606px" }}
      >
        <NavBar
          isAuthMobileSideBar={isAuthMobileSideBar}
          isSidebarOpened={isSidebarOpened}
          handleOpenAuth={handleOpenAuthSidebar}
          handleCloseAuth={handleCloseAuthSidebar}
          handleOpenSidebar={handleOpenMobileSideBar}
          handleCloseSidebar={handleCloseAuthSidebar}
          handleToggleSidebar={handleToggleSidebar}
          activeNav={"casino"}
        />
                <div class="header-indecator"></div>

        <div className="container">
          {casinoBlocked ? (
            <h3 style={{ color: "white" }}>
              Casino is currently in maintenance mode.
            </h3>
          ) : (
            <section id="section-games" className="section-games">
              <CategoryNavbar type={"casino"} tab={tab} setTab={setTab} />
              <div className="wrap-game-listing">
                <GamesList
                  openLogin={openLogin}
                  setOpenLogin={setOpenLogin}
                  type={"casino"}
                  category={tab}
                  provider={provider}
                  setProvider={setProvider}
                  fromProvider={fromProvider}
                />
              </div>
            </section>
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default Casino;

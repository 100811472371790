import React from 'react'
import { useTranslation } from "react-i18next";

const SelectLang = ({selectLangOpen,selectedLanguage,setSelectedLanguage}) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = language => {
      setSelectedLanguage(language);
      i18n.changeLanguage(language);
      localStorage.setItem("lang", language);
      selectLangOpen(false)
    };

  return (
    <div id="lang-drop" style={{ display: selectLangOpen ? "block" : "none" }}>
    <div onClick={()=>{
      handleLanguageChange('fr')
    }} style={{ cursor:'pointer' }} title="French" data-lang="fr" className="language-list-item">
      <span className="flag flag-fr" alt="French"></span>
    </div>

    <div onClick={()=>{
      handleLanguageChange('ar')
    }} style={{ cursor:'pointer' }} title="عربى" data-lang="ar" className="language-list-item">
      <span className="flag flag-ar-iq" alt="عربى"></span>
    </div>
  </div>
  )
}

export default SelectLang
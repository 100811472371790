import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux"
import { authentification } from "../../redux/actions/auth";
import { RESET_AUTH_ERROR } from "../../redux/types";

const LoginModal = ({setOpenLogin,openLogin}) => {
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state) => state.auth);
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const handlePassword = (e) => {
    setpassword(e.target.value);
    dispatch({
      type: RESET_AUTH_ERROR
    })
  }

  useEffect(() => {
    if(user) {
        setOpenLogin(false)
    }
  }, [user])
  

  const handleUsername = (e) => {
    setusername(e.target.value);
    dispatch({
      type: RESET_AUTH_ERROR
    })
  }
  const handleLogin = (e) => {
    e.preventDefault()
    let body = {
      username: username,
      password: password
    }
    dispatch(authentification(body))
  }
  return (
    <div className={`modal ${openLogin ? "fade show" : ""}`} id="modal-login" tabIndex="-1" role="dialog" 
        style={{display: openLogin ? "block": "none", paddingLeft: "0px"}}
    >
    <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title">Login</h5>
            <div onClick={ () => { setOpenLogin(false) }} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            </div>
        </div>
        <div className="modal-body">
            <form className="form-login">
                <div className="input-group mb-2">
                    <div className="input-group-addon">
                        <i className="fa fa-user"></i>
                    </div>
                    <input onChange={handleUsername} type="text" className="form-control login-username" placeholder="Username" required="" />
                </div>

                <div className="input-group mb-2">
                    <div className="input-group-addon">
                        <i className="fa fa-lock"></i>
                    </div>
                    <input onChange={handlePassword} type="password" className="form-control login-password" placeholder="Password" required="" />
                </div>

                <div onClick={handleLogin} 
                    className={`btn btn-primary btn-block btn-login-form ${loading && "btn-loading"}`}
                    data-original-title="" title="">
                    Login</div>
                <span className="login-errors" style={{display: "none"}}></span>
            </form>
        </div>
        </div>
    </div>
    </div>
  )
}

export default LoginModal
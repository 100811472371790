import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGames } from "../../redux/actions/casino";
import { RESET_GAMES_STATE } from "../../redux/types";
import LoadMore from "../shared/LoadMore";
import Filter from "../casino/Filter";
import GameCard from "./GameCard";

const GamesList = ({ setOpenLogin, type, provider, setProvider }) => {
  const dispatch = useDispatch();
  const [searchAny, setSearchAny] = useState([]);
  const [localGamesLive, setlocalGamesLive] = useState([]);
  const [pageLive, setPageLive] = useState(0);
  const { games, loading } = useSelector((state) => state.casino);
  const [show, setShow] = useState(false);
  const [playUrl, setPlayUrl] = useState("");
  const [gapi, setGapi] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  // we should separate the page from the filters cz we didn't emptying the localGames.
  const handlePagination = useCallback(() => {
    dispatch(getGames(type, provider, "", pageLive, searchAny));
  }, [pageLive]);

  // we just call handlePagination when paginate changes.
  useEffect(() => {
    handlePagination();
  }, [handlePagination]);

  // this is the other filters changes.
  const handleGetGames = useCallback(() => {
    dispatch(getGames(type, provider, "", pageLive, searchAny));
  }, [provider, searchAny]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      handleGetGames();
    }, 1000);
    return () => {
      clearTimeout(timeOut);
      setlocalGamesLive([]);
      dispatch({ type: RESET_GAMES_STATE });
    };
  }, [handleGetGames]);

  useEffect(() => {
    if (games && games.items) {
      setPageLive(games.currentPage);
      if (pageLive === 0) {
        setlocalGamesLive(games.items);
      } else {
        setlocalGamesLive([...localGamesLive, ...games.items]);
      }
    }
  }, [games]);


  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {/* call the filter component, to separate code. */}
          <Filter
            type={"live casino"}
            provider={provider}
            setProvider={setProvider}
            searchAny={searchAny}
            setSearchAny={setSearchAny}
          />
          <div className="row row-game-listing">
            {loading && <div className="game-spinner"></div>}
            {localGamesLive !== undefined &&
              localGamesLive !== null &&
              localGamesLive.length > 0 &&
              localGamesLive.map((item, index) => {
                return (
                  <GameCard
                    setOpenLogin={setOpenLogin}
                    item={item}
                    idx={index}
                  />
                );
              })}
          </div>
          {games !== undefined &&
            games !== null &&
            pageLive + 1 < games.totalPages && (
              <LoadMore
                loading={loading}
                page={pageLive}
                setPage={setPageLive}
              />
            )}
        </div>
      </div>
    </div>
  );
};
export default GamesList;

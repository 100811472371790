import {
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILED,
    GET_USER_DETAILS_SPINNER_EN,
    GET_USER_DETAILS_SPINNER_DIS,

    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_SPINNER_EN,
    CHANGE_PASSWORD_SPINNER_DIS,

    TRANSFER_HISTORY_SPINNER_EN,
    TRANSFER_HISTORY_SUCCESS,
    TRANSFER_HISTORY_FAILED,
    TRANSFER_HISTORY_SPINNER_DIS,


    CASINO_HISTORY_SPINNER_EN,
    CASINO_HISTORY_SUCCESS,
    CASINO_HISTORY_FAILED,
    CASINO_HISTORY_SPINNER_DIS,

    CONVERT_LOADING_ENABLE,
    CONVERT_SUCCESS,
    CONVERT_LOADING_DISABLE,
    CONVERT_FAILED
} from '../types'
import axios from 'axios'
import { API_URL } from "../../utils/constants";

export const getUserDetails = (token) => async dispatch => {
    try {
        dispatch({ type: GET_USER_DETAILS_SPINNER_EN })
        const res = await axios.get(`${API_URL}/api/user`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        // if (res.data) {
        dispatch({
            type: GET_USER_DETAILS_SUCCESS,
            payload: res.data
        })
        // }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: GET_USER_DETAILS_FAILED,
                payload: err.response.data.message
            })
            dispatch({ type: GET_USER_DETAILS_SPINNER_DIS })
        } else {
            dispatch({
                type: GET_USER_DETAILS_FAILED,
                payload: "Uknown Error."
            })
            dispatch({ type: GET_USER_DETAILS_SPINNER_DIS })
        }
    }
}




export const changePasswordUser = (body, token) => async dispatch => {
    try {
        dispatch({ type: CHANGE_PASSWORD_SPINNER_EN })
        const res = await axios.put(`${API_URL}/api/user/change_password`, body, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        // if (res.data) {
        dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: res.data.message
        })
        // }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: CHANGE_PASSWORD_FAILED,
                payload: err.response.data.message
            })
            dispatch({ type: CHANGE_PASSWORD_SPINNER_DIS })
        } else {
            dispatch({
                type: CHANGE_PASSWORD_FAILED,
                payload: "Uknown Error."
            })
            dispatch({ type: CHANGE_PASSWORD_SPINNER_DIS })
        }
    }
}



export const getTransferHistory = (token, page) => async dispatch => {
    try {
        dispatch({ type: TRANSFER_HISTORY_SPINNER_EN })
        const res = await axios.get(`${API_URL}/api/depot_retrait?page=${page}&size=10`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        // if (res.data) {
        dispatch({
            type: TRANSFER_HISTORY_SUCCESS,
            payload: res.data
        })
        dispatch({ type: TRANSFER_HISTORY_SPINNER_DIS })
        // }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: TRANSFER_HISTORY_FAILED,
                payload: err.response.data.message
            })
            dispatch({ type: TRANSFER_HISTORY_SPINNER_DIS })
        } else {
            dispatch({
                type: TRANSFER_HISTORY_FAILED,
                payload: "Uknown Error."
            })
            dispatch({ type: TRANSFER_HISTORY_SPINNER_DIS })
        }
    }
}

export const convertCashbackApi = () => async dispatch => {
    const token = localStorage.getItem('token')

    try {
        dispatch({ type: CONVERT_LOADING_ENABLE })
        var res = await axios.post(`${API_URL}/api/cashback`, {
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (res.data) {
            {
                dispatch({
                    type: CONVERT_SUCCESS,
                    payload: res.data.success
                })
            }


        }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: CONVERT_FAILED,
                payload: err.response.data.message
            })
            dispatch({ type: CONVERT_LOADING_DISABLE })
        } else {
            dispatch({
                type: CONVERT_FAILED,
                payload: "Uknown Error."
            })
            dispatch({ type: CONVERT_LOADING_DISABLE })
        }
    }
}

export const getCasinoHistory = (token, page) => async dispatch => {
    try {
        dispatch({ type: CASINO_HISTORY_SPINNER_EN })
        const res = await axios.get(`${API_URL}/api/casino_history?page=${page}&size=40`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        // if (res.data) {
        dispatch({
            type: CASINO_HISTORY_SUCCESS,
            payload: res.data
        })
        dispatch({ type: CASINO_HISTORY_SPINNER_DIS })
        // }
    } catch (err) {
        if (err?.response?.data) {
            dispatch({
                type: CASINO_HISTORY_FAILED,
                payload: err.response.data.message
            })
            dispatch({ type: CASINO_HISTORY_SPINNER_DIS })
        } else {
            dispatch({
                type: CASINO_HISTORY_FAILED,
                payload: "Uknown Error."
            })
            dispatch({ type: CASINO_HISTORY_SPINNER_DIS })
        }
    }
}